import React from "react";

function PeSpinner() {
    return (
        <>
            <div className="loader">
                <section>
                    <span className="pe-loader"> </span>
                </section>
            </div>
        </>
    );
}

export default PeSpinner;
