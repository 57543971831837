const baseUrl = () => {
  const host = window.location.host;
  const hostSplitArr = host.split(".");
  if (hostSplitArr[0] === "dev") return "http://dev.api.payemi.net/";
  else return "http://api.payemi.net/";
};

const baseUrlMedius = () => {
  const host = window.location.host;
  const hostSplitArr = host.split(".");
  if (hostSplitArr[0] === "dev") return "https://dev.api.themedius.ai";
  else return "https://api.themedius.ai";
};

const getBaseUrlMedius = baseUrlMedius();
const getBaseUrl = baseUrl();

export { getBaseUrl, getBaseUrlMedius };
