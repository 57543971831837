import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";

// Images
import Hdfc from "../Static/Images/hdfc.webp";
import GooglePlay from "../Static/Images/google-play-logo.webp";
import ScratchCard from "../Static/Images/scratch-half-2.png";
import CarRound from "../Static/Images/Car-PO.webp";
import Warning from "../Static/Images/warning.gif";
import Fail from "../Static/Images/order-fail.gif";

import { useGlobalContext } from "../context";
import { useHistory, useParams } from "react-router-dom";
import BelowIcon from "../Components/BelowIcon";

import swal from "sweetalert";
import BillDetails from "../Components/BillDetails";
import {getBaseUrl} from "../API/getBaseUrl";
var fileDownload = require("js-file-download");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const TransitionUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PayemiFailure = () => {
  const { BillPaySevenopen, BillPaySevenClose } = useGlobalContext();
  const [billerInfo, setBillerInfo] = useState([]);

  // pay emi fetch bill data
  const fetch_bill_data = JSON.parse(localStorage.getItem("fetch_bill_data"));
  const userPaymentInfo = fetch_bill_data?.payload;
  const amount = localStorage.getItem("amount");
  const bill_id = userPaymentInfo?.map((data) => data?.id);
  // condition bill_id

  const [error, setError] = useState("");
  console.log(bill_id);
  useEffect(() => {
    if (bill_id) {
      fetch(
        `${getBaseUrl}getbilldetails-noauth/?bill_id=${bill_id}`,
        {
          method: "GET",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data?.error) {
            setError(data.message);
          } else {
            setBillerInfo(data?.data);
            setError("");
          }
        });
    }
  }, []);
  let history = useHistory();
  // handle_guest_try_agian
  const handle_guest_try_agian = () => {
    console.log("done");
    history?.goBack();
  };

  const handleDownload = async (id) => {
    fetch(`${getBaseUrl}getPaymentReceiptPDF/?bill_id=${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // fileDownload(data?.file_url, "payment_receipt.pdf");
        const link = document.createElement("a");
        link.href = data?.file_url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return error ? (
    <p>{error}</p>
  ) : (
    <div>
      <Dialog
        fullScreen
        open={true}
        onClose={BillPaySevenClose}
        TransitionComponent={TransitionUp}
        className="side-pane side-pane-second"
      >
        <AppBar sx={{ position: "relative" }} className="side-pane-header">
          <div className="d-flex align-center justify-between">
            {/* <div onClick={FailedClose} className="d-flex align-center">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.928 3.072c-4.095-4.096-10.76-4.096-14.856 0s-4.096 10.76 0 14.856 10.76 4.096 14.856 0 4.096-10.76 0-14.856zm-3.285 11.57a.808.808 0 0 1-1.143 0l-3-3-3.142 3.143a.808.808 0 1 1-1.143-1.143L9.357 10.5l-3-3A.808.808 0 1 1 7.5 6.357l3 3L13.357 6.5A.808.808 0 1 1 14.5 7.643L11.643 10.5l3 3a.808.808 0 0 1 0 1.142z"
                fill="#000"
                fillRule="nonzero"
                opacity=".495"
              />
            </svg>
          </div> */}
          </div>
        </AppBar>
        {billerInfo?.map((data) => (
          <div className="py-0 px-3">
            <div className="container-fluid">
              <div className="mt-0">
                <div className="row mb-3">
                  <div className="col-12">
                    <p className="text-center">
                      <img src={Fail} className="w-75" />
                    </p>
                    <p className="mb-4 tx-23 text-center text-danger">
                      Payment Failed
                    </p>
                    <p className="mb-0 text-center text-black">
                      <span>To </span>
                      {
                        JSON.parse(localStorage.getItem("currentBankBranch"))
                          ?.biller_bank__bank_name
                      }
                      Bank Limited
                    </p>

                    <p className="mb-4 text-center text-muted">
                      {localStorage.getItem("currentCategory")}
                    </p>
                    <div className="w-auto d-flex align-center justify-center mb-2">
                      <p className="tx-23 mb-0 me-2 text-muted">&#8377;</p>
                      <p className="tx-60 mb-0 me-2 text-black weight-300 lh-1">
                        {amount}
                      </p>
                    </div>
                    <p className="mb-0 text-center text-black">
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="M6.761 17.647a7.703 7.703 0 0 1-.002-10.893 7.703 7.703 0 0 1 10.893.002 7.703 7.703 0 0 1 .002 10.893 7.703 7.703 0 0 1-10.893-.002z"
                            fill="#D41700"
                          />
                          <path
                            d="M13.691 9.726a.7.7 0 0 1 .99.99l-3.96 3.96a.7.7 0 0 1-.99-.99l3.96-3.96z"
                            fill="#FFF"
                          />
                          <path
                            d="M9.73 10.715a.7.7 0 1 1 .99-.99l3.962 3.962a.7.7 0 1 1-.99.99L9.73 10.715z"
                            fill="#FFF"
                          />
                        </g>
                      </svg>
                      Failure | {("", data["transaction_date_and_time"])}
                    </p>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-12">
                    <button
                      onClick={handle_guest_try_agian}
                      type="submit"
                      className="btn btn-primary w-100"
                    >
                      Try Again
                    </button>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="tx-16 text-black">Receipt</div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="d-flex align-center justify-between">
                      <p className="m-0 pe-3 text-muted">Biller name</p>
                      <p className="m-0">
                        <strong>{data?.customer_name}</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <BillDetails data={data} />

                <div className="row mb-5 mt-4 pt-0">
                  <div className="col-6">
                    <button
                      type="submit"
                      className="btn btn-outline-primary w-100"
                      onClick={() => (window.location.href = "/home")}
                    >
                      Proceed to Home
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      type="submit"
                      className="btn btn-outline-primary w-100"
                      onClick={(e) => handleDownload(data?.id)}
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="#412E8D" fillRule="evenodd">
                          <path d="M4.125 14.438a.687.687 0 1 1 1.375 0V16.5c0 .38.308.688.688.688h9.625c.38 0 .687-.308.687-.688v-2.063a.687.687 0 1 1 1.375 0V16.5c0 1.14-.923 2.063-2.063 2.063H6.188A2.062 2.062 0 0 1 4.124 16.5v-2.063z" />
                          <path d="M13.264 10.514a.687.687 0 1 1 .972.972l-2.75 2.75a.687.687 0 0 1-.972 0l-2.75-2.75a.687.687 0 1 1 .972-.972L11 12.778l2.264-2.264z" />
                          <path d="M10.313 4.125a.687.687 0 1 1 1.374 0v9.625a.687.687 0 1 1-1.374 0V4.125z" />
                        </g>
                      </svg>
                      Download
                    </button>
                  </div>
                </div>
                <BelowIcon />
              </div>
            </div>
          </div>
        ))}
      </Dialog>
    </div>
  );
};

export default PayemiFailure;
