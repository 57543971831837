import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { fetchCheckOtp, fetchSendOtp } from "../API";

const ContextData = (props) => {
  // react router
  const history = useHistory();
  const [number, setNumber] = React.useState();

  const [verifyError, setVerifyError] = React.useState("");
  //   verfication useState
  const [code1, setCode1] = React.useState();
  const [code2, setCode2] = React.useState();
  const [code3, setCode3] = React.useState();
  const [code4, setCode4] = React.useState();

  const HandleSendOtp = async (number) => {
    const res = await fetchSendOtp(number);
    if (res?.error) {
      console.log(res?.message);
    } else {
      sessionStorage.setItem("user_status", res.new_user);
      localStorage.setItem("MobileNumber", number);
      alert(`your verify code is ${res.payload}`);
    }
  };

  const userStatus = sessionStorage.getItem("user_status");
  //   handle verify
  const handleVerify = async () => {
    const data = await fetchCheckOtp(number, code1, code2, code3, code4);
    if (data?.error) {
      setVerifyError(data?.message);
    } else {
      localStorage.setItem("profile", data?.id);
      localStorage.setItem("access_token", data?.access_token);
      localStorage.setItem("full_name", data?.fullname);
      localStorage.setItem("login_time", new Date());
      localStorage.setItem("photo", data.profile_url);
      console.log(data);
      if (data?.access_token) {
        if (userStatus === "true") {
          window.location.href = "/category";
        } else {
          window.location.href = "/home";
        }
      }
    }
  };
  return {
    number,
    setNumber,
    verifyError,
    setVerifyError,
    HandleSendOtp,
    handleVerify,
    code1,
    setCode1,
    code2,
    setCode2,
    code3,
    setCode3,
    code4,
    setCode4,
  };
};

export default ContextData;
