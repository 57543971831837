import React from "react";

const LoginButton = (props) => {
  const subDomain = localStorage.getItem("subDomain");
  const mobileNumber = localStorage.getItem("guest-mobile-number");

  const handleLogin = () => {
    window.location.href = window.location.href
      .replace(subDomain + ".", "")
      .replace(props.name, `verify-otp/${mobileNumber}`);
  };

  return (
    <div className="col-6">
      <button
        type="submit"
        className="btn btn-outline-primary w-100"
        onClick={handleLogin}
      >
        Proceed to Login
      </button>
    </div>
  );
};

export default LoginButton;
