import axios from "axios";
import { useEffect, useState } from "react";
import { useParams,useLocation } from "react-router-dom";
import PeSpinner from "../Components/PeSpinner";
import { getBaseUrlMedius } from "../API/getBaseUrl";
import { Helmet } from 'react-helmet';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function PayEmiRedirect({ source = "sms" }) {
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState("");
    const [client_name, setClientname] = useState([]);
    const params = useParams();
    const query = useQuery();

    useEffect(async () => {
        setLoading(true);
        setErrMsg("");
        const number = query.get("n") || params?.id;


        try {
            const res = await axios.get(`${getBaseUrlMedius}/dashboard/api/get_payment_link/`, {
                params: { number, source },
            });

            if (res.data) {
                if (res?.data?.status && !!res.data?.data?.payment_link) window.location.href = res.data?.data?.payment_link;
                else {
                    setErrMsg(res.data?.message);
                    setClientname(res.data.data?.client_name)
                    setLoading(false);
                }
            } else {
                setErrMsg("Not Found!");
                setLoading(false);
            }
        } catch (error) {
            console.log(error?.message);
            setErrMsg(error?.message || "!Unknown error occured, Try Again Later");
            setLoading(false);
        }
    }, [params]);

    return (
        <>
             <Helmet>
      
      <title>{client_name||"Medius"}</title>

    </Helmet>
        
        <div className="d-flex min-vh-100 align-items-center justify-content-center p-4">
            {loading ? <PeSpinner /> : <p className="text-danger text-center">{errMsg}</p>}
        </div>
        
        </>
    );
}
