import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { Button } from "@mui/material";

const SingleNotification = (props) => {
  const {
    notification_logo,
    notification_name,
    notification_action,
    notification_status,
    notification_type,
    notification_time,
    id,
  } = props.notifications;
  const handleClear = props.handleClear;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const BooleanOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return notification_status ? (
    <div className="py-0 px-0 mb-4">
      <div className="ntfn-card bg-white py-3 px-3">
        <div className="d-flex justify-between">
          <div className="d-flex justify-between">
            <div>
              <img
                src={notification_logo}
                height="21"
                className="me-2"
                alt=""
              />
            </div>
            <div className="ms-3">
              <p className="mb-2 lh-1 text-black tx-14">{notification_name}</p>
              <p className="mb-2 lh-1 tx-12 text-muted">
                {moment(notification_time).startOf("hour").fromNow()}
              </p>
              {notification_action && (
                <>
                  {notification_type == "transaction" ? (
                    <button type="submit" className="btn btn-primary btn-sm">
                      Pay Now & Earn Cashback
                    </button>
                  ) : notification_type == "cashback" ? (
                    <button type="submit" className="btn btn-primary btn-sm">
                      Redem Now
                    </button>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
          <div>
            <Button
              id="demo-positioned-button"
              aria-controls={BooleanOpen ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={BooleanOpen ? "true" : undefined}
              onClick={handleClick}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#1A1A1A" fillRule="evenodd" opacity=".44">
                  <path d="M11 13.063a2.062 2.062 0 1 1 0-4.125 2.062 2.062 0 0 1 0 4.124zm0-1.376a.687.687 0 1 0 0-1.374.687.687 0 0 0 0 1.374zM11 7.563a2.062 2.062 0 1 1 0-4.125 2.062 2.062 0 0 1 0 4.124zm0-1.375a.687.687 0 1 0 0-1.375.687.687 0 0 0 0 1.375zM11 18.563a2.062 2.062 0 1 1 0-4.125 2.062 2.062 0 0 1 0 4.124zm0-1.375a.687.687 0 1 0 0-1.375.687.687 0 0 0 0 1.374z" />
                </g>
              </svg>
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={BooleanOpen}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => handleClose()}>
                <p onClick={() => handleClear("single_clear", id)}>Delete</p>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SingleNotification;
