import React from "react";
import CarRound from "../Static/Images/Car-PO.webp";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";

const PayEmiTransPaid = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const BooleanOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const successData = JSON.parse(localStorage.getItem("TransData"));
  return (
    <div className="side-pane side-pane-second">
      <AppBar sx={{ position: "relative" }} className="side-pane-header">
        <div
          className="d-flex align-center justify-between"
          style={{ marginLeft: "20px", marginTop: "5px" }}
        >
          <div
            onClick={() => {
              history.push("transaction-history");
            }}
          >
            <svg
              width="27"
              height="27"
              viewBox="0 0 27 27"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="#505050" fillRule="evenodd">
                <path d="M20.25 12.656a.844.844 0 1 1 0 1.688H6.75a.844.844 0 1 1 0-1.688h13.5z" />
                <path d="m7.943 13.5 4.466 4.466a.844.844 0 1 1-1.193 1.193l-5.063-5.062a.844.844 0 0 1 0-1.194l5.063-5.062a.844.844 0 0 1 1.193 1.193L7.943 13.5z" />
              </g>
            </svg>
          </div>
          <div className="d-flex align-center">
            <div>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#1A1A1A" fillRule="evenodd" opacity=".6">
                  <path d="M15.125 8.938a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5zm0-1.376a1.375 1.375 0 1 0 0-2.75 1.375 1.375 0 0 0 0 2.75zM6.875 13.75a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5zm0-1.375a1.375 1.375 0 1 0 0-2.75 1.375 1.375 0 0 0 0 2.75zM15.125 18.563a2.75 2.75 0 1 1 0-5.5 2.75 2.75 0 0 1 0 5.5zm0-1.375a1.375 1.375 0 1 0 0-2.75 1.375 1.375 0 0 0 0 2.75z" />
                  <path d="M8.31 12.632a.688.688 0 0 1 .692-1.188l4.695 2.736a.688.688 0 0 1-.692 1.188L8.31 12.632zM12.998 6.632a.688.688 0 0 1 .693 1.187l-4.689 2.737a.688.688 0 0 1-.693-1.188l4.689-2.736z" />
                </g>
              </svg>
            </div>
            <div>
              <Button
                id="demo-positioned-button"
                aria-controls={BooleanOpen ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={BooleanOpen ? "true" : undefined}
                onClick={handleClick}
                className="p-0 m-btn ms-3"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="#1A1A1A" fillRule="evenodd" opacity=".44">
                    <path d="M11 13.063a2.062 2.062 0 1 1 0-4.125 2.062 2.062 0 0 1 0 4.124zm0-1.376a.687.687 0 1 0 0-1.374.687.687 0 0 0 0 1.374zM11 7.563a2.062 2.062 0 1 1 0-4.125 2.062 2.062 0 0 1 0 4.124zm0-1.375a.687.687 0 1 0 0-1.375.687.687 0 0 0 0 1.375zM11 18.563a2.062 2.062 0 1 1 0-4.125 2.062 2.062 0 0 1 0 4.124zm0-1.375a.687.687 0 1 0 0-1.375.687.687 0 0 0 0 1.374z" />
                  </g>
                </svg>
              </Button>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={BooleanOpen}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem onClick={handleClose}>Refresh</MenuItem>
                <MenuItem onClick={handleClose}>Get Help</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </AppBar>
      <div
        className="py-0 px-0 mb-5"
        style={{ marginLeft: "30px", marginRight: "20px" }}
      >
        <div className="mt-4">
          <div className="row mb-5">
            <div className="col-12">
              <p className="text-center">
                <img src={CarRound} className="head-icon me-2" />
              </p>
              <p className="mb-0 text-center text-black">
                {successData.biller_name}
              </p>
              <p className="mb-4 text-center text-muted">CAR Loan</p>
              <div className="w-auto d-flex align-center justify-center mb-2">
                <p className="tx-23 mb-0 me-2 text-muted">&#8377;</p>
                <p className="tx-60 mb-0 me-2 text-black weight-300 lh-1">
                  {successData.amount}
                </p>
              </div>
              <p className="mb-0 text-center text-black">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-2"
                >
                  <path
                    d="M6 0a6 6 0 1 0 6 6 6.006 6.006 0 0 0-6-6zm2.871 4.94-3.09 3.09a.75.75 0 0 1-1.061 0L3.129 6.44a.75.75 0 0 1 1.06-1.061l1.061 1.06 2.56-2.56a.75.75 0 0 1 1.061 1.06z"
                    fill="#0B7C05"
                    fillRule="nonzero"
                  />
                </svg>
                Completed |{" "}
                {moment().utcOffset("+05:30").format("DD MMM YY hh:mm a")}
              </p>
            </div>
          </div>
          <div className="bg-gray px-4 py-4">
            <div className="row mb-3">
              <div className="col-12">
                <p className="m-0 pe-3 text-black">Transaction ID</p>
                <p className="m-0 text-black">
                  {successData.razorpay_transaction_id}
                </p>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12">
                <p className="m-0 pe-3 text-black">
                  To - {successData.biller_name}
                </p>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12">
                <p className="m-0 pe-3 text-black">
                  From - {successData.customer_name}
                </p>
                <p className="m-0 text-black">{successData.customer_email}</p>
              </div>
            </div>
            <div className="row mb-0">
              <div className="col-12">
                <p className="m-0 pe-3 text-black">
                  Biller Id - {successData.biller_id}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3 mt-2 px-4 pt-0">
        <div className="col-6"></div>
        <div className="col-6">
          <Link to="/register-complaint">
            <button type="submit" className="btn btn-outline-primary w-100">
              Having Issue
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PayEmiTransPaid;
