import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MainMenu from "../../src/Components/MainMenu";
import { useTheme } from "@mui/material/styles";
import swal from "sweetalert";
import { getProfileInfo, profilePostData } from "../API";

function Profile(props) {
  const [profileInfo, setProfileInfo] = useState();

  const [image, setImage] = useState({
    image: "https://i.ibb.co/fY5xtWh/admin.png",
  });

  // mobile number
  const mobileNumber = localStorage.getItem("MobileNumber");

  const theme = useTheme();
  const [openq, setOpenq] = React.useState(false);

  const [open1, setOpen1] = useState(true);
  const handleDrawerOpen = () => {
    setOpenq(true);
    setOpen1(false);
  };
  const handleDrawerClose = () => {
    setOpenq(false);
    setOpen1(true);
  };

  const fetchProfile = async () => {
    const res = await getProfileInfo(mobileNumber);
    if (res?.error) {
    } else {
      setProfileInfo(res?.data[0]);
      localStorage.setItem("photo", res.data[0].profile_url);
    }
  };
  const [profileData, setProfileData] = useState({});
  let profileDefault = {};
  useEffect(() => {
    profileDefault = {
      email: profileInfo?.email || "",
      user_name: profileInfo?.user_name || "",
      address: profileInfo?.address || "",
      fullname: profileInfo?.fullname || "",
      phone_number: mobileNumber || "",
    };
    setProfileData({
      ...profileDefault,
    });
  }, [profileInfo?.email]);

  const [imageurl, setImageUrl] = useState();

  useEffect(() => {
    fetchProfile();
    // setImageUrl(profileInfo?.profile_url);
  }, []);

  const handleChange = (name, val) => {
    setProfileData((prev) => ({ ...prev, [name]: val }));
  };

  const handleImageSubmit = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage({
        image: URL.createObjectURL(e.target.files[0]),
      });
      setImageUrl(e.target.files[0]);
    }

    e.preventDefault();
  };
  const [error, setError] = useState("");
  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (imageurl) {
      formData.append("profile_url", imageurl);
    }
    formData.append("email", profileData?.email);
    formData.append("user_name", profileData?.user_name);
    formData.append("address", profileData?.address);
    formData.append("fullname", profileData?.fullname);
    formData.append("phone_number", mobileNumber);

    const res = await profilePostData(mobileNumber, formData);
    if (res?.error) {
      swal("Good job!", `${res?.message}`, "warning");
    } else {
      localStorage.setItem("photo", res.data[0].profile_url);
      localStorage.setItem("full_name", res.data[0].fullname);

      swal("Good job!", "Your Profile Update", "success").then(() =>
        // window.location.reload(true)
        console.log(res)
      );
    }
  };
  return (
    <>
      <Box sx={{ display: "flex" }} className="menu-sidebar">
        <Drawer
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={openq}
        >
          <MainMenu />
        </Drawer>
      </Box>
      <div className={open1 ? "menu-close" : "menu-open"}>
        <form>
          <div className="home-main header-second">
            <div className="drawer-toggle">
              <a onClick={handleDrawerClose}></a>
            </div>
            <div className="d-flex align-center justify-between px-4">
              <div className="d-flex align-center position-relative head-info">
                <div className="me-2">
                  <a onClick={handleDrawerOpen}>
                    <svg
                      width="37"
                      height="37"
                      viewBox="0 0 37 37"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="#505050" fillRule="evenodd">
                        <path d="M11.563 12.719a1.156 1.156 0 0 1 0-2.313h15.03a1.156 1.156 0 0 1 0 2.313h-15.03zM11.563 19.656a1.156 1.156 0 0 1 0-2.312h15.03a1.156 1.156 0 0 1 0 2.312h-15.03zM11.563 26.594a1.156 1.156 0 0 1 0-2.313h15.03a1.156 1.156 0 0 1 0 2.313h-15.03z" />
                      </g>
                    </svg>
                  </a>
                </div>
                <p className="mb-0">Profile</p>
              </div>
            </div>
            <div className="py-3 px-4 text-center profile-img ">
              {image?.image == "https://i.ibb.co/fY5xtWh/admin.png" ? (
                <img
                  id="target"
                  width={"120px"}
                  height={"120px"}
                  className="profile-img rounded-circle"
                  src={`${profileInfo?.profile_url}`}
                  alt=""
                />
              ) : (
                <img
                  id="target"
                  width={"120px"}
                  height={"120px"}
                  className="profile-img rounded-circle"
                  src={image?.image}
                  alt="this is "
                />
              )}
              <p className="mb-0 tx-16">
                <label htmlFor="files" className="mb-0 tx-16 btn">
                  Edit
                </label>
                <input
                  onChange={(e) => handleImageSubmit(e)}
                  id="files"
                  style={{ visibility: "hidden" }}
                  type="file"
                />
              </p>
            </div>
            <div className="py-2 px-3 mx-3 profile-info mb-3">
              <p className="mb-0 tx-14 text-muted">Full Name</p>
              <input
                onChange={(e) => handleChange("fullname", e.target.value)}
                type="text"
                className="form-control"
                defaultValue={profileData?.fullname}
                required
              />
            </div>

            <div className="py-2 px-3 mx-3 profile-info mb-3">
              <p className="mb-0 tx-14 text-muted">Email</p>
              <input
                onChange={(e) => handleChange("email", e.target.value)}
                type="email"
                className="form-control"
                defaultValue={profileInfo?.email || ""}
                required
              />
            </div>

            <div className="py-2 px-3 mx-3 profile-info mb-3">
              <p className="mb-0 tx-14 text-muted">Phone Number</p>
              <input
                type="text"
                className="form-control"
                value={mobileNumber || ""}
                readOnly
              />
            </div>

            <div className="py-2 px-3 mx-3 profile-info mb-3">
              <p className="mb-0 tx-14 text-muted">Username</p>
              <input
                onChange={(e) => handleChange("user_name", e.target.value)}
                type="text"
                className="form-control"
                defaultValue={profileInfo?.user_name || ""}
                required
              />
            </div>

            <div className="py-2 px-3 mx-3 profile-info mb-3">
              <p className="mb-0 tx-14 text-muted">Address</p>
              <textarea
                onChange={(e) => handleChange("address", e.target.value)}
                className="form-control form-textarea"
                defaultValue={profileInfo?.address || ""}
                required
              />
            </div>

            <div className="py-2 mx-3 mb-3">
              <input
                type="submit"
                onClick={(e) => handleUpdate(e)}
                className="btn btn-primary w-100"
                value={"Update"}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Profile;
