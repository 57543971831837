import React from "react";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "../context";

const PayEMiDetails = ({ data, amount }) => {
    const { BillPaySixsetOpen } = useGlobalContext();
    const BillPaySixClickOpen = () => {
        BillPaySixsetOpen(true);
    };

    const location = useLocation();

    return (
        <>
            <div key={data.profile_id} className="py-0 px-3">
                <div className="row mb-3">
                    <div className="col-12">
                        <div style={{ fontWeight: "500" }} className="tx-16 text-black">
                            Payment Information
                        </div>
                    </div>
                </div>
                {data?.RespDueDate != 0 && data?.RespDueDate && (
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="d-flex align-center justify-between">
                                <p className="m-0 pe-3 text-muted">Due Date</p>
                                <p className="m-0">{data?.RespDueDate}</p>
                            </div>
                        </div>
                    </div>
                )}
                {/* {data["charges_levied"] != 0 && data["Base Bill Amount"] && (
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="d-flex align-center justify-between">
                                <p className="m-0 pe-3 text-muted">Charges Levied</p>
                                <p className="m-0">{data["charges_levied"]}</p>
                            </div>
                        </div>
                    </div>
                )} */}
                {data["Base Bill Amount"] != 0 && data["Base Bill Amount"] && (
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="d-flex align-center justify-between">
                                <p className="m-0 pe-3 text-muted">Base Bill Amount</p>
                                <p className="m-0">{data["Base Bill Amount"]}</p>
                            </div>
                        </div>
                    </div>
                )}
                {data["amountOptions"]["Late Payment Fee"] != 0 && data["amountOptions"]["Late Payment Fee"] && (
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="d-flex align-center justify-between">
                                <p className="m-0 pe-3 text-muted">Late Payment Fee</p>
                                <p className="m-0">{data["amountOptions"]["Late Payment Fee"]}</p>
                            </div>
                        </div>
                    </div>
                )}
                {data["amountOptions"]["Additional Charges"] != 0 && data["amountOptions"]["Additional Charges"] && (
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="d-flex align-center justify-between">
                                <p className="m-0 pe-3 text-muted">Additional Charges</p>
                                <p className="m-0">{data["amountOptions"]["Additional Charges"]}</p>
                            </div>
                        </div>
                    </div>
                )}
                {data["amountOptions"]["Fixed Charges"] != 0 && data["amountOptions"]["Fixed Charges"] && (
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="d-flex align-center justify-between">
                                <p className="m-0 pe-3 text-muted">Fixed Charges</p>
                                <p className="m-0"> {data["amountOptions"]["Fixed Charges"]}</p>
                            </div>
                        </div>
                    </div>
                )}
                {data["tenure"] != 0 && data["tenure"] && (
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="d-flex align-center justify-between">
                                <p className="m-0 pe-3 text-muted">Tenure</p>
                                <p className="m-0">{data["tenure"]} months</p>
                            </div>
                        </div>
                    </div>
                )}
                {amount != 0 && amount && (
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="d-flex align-center justify-between">
                                <p className="m-0 pe-3 text-muted">Minimum Payable Amount</p>
                                <p className="m-0">Rs. {amount}</p>
                            </div>
                        </div>
                    </div>
                )}

                {location.pathname === "/pay-emi" && (
                    <>
                        <p className="text-dark fs-5">Amounts</p>
                        {data["amount"] != 0 && data["amount"] && (
                            <div className="row mb-3">
                                <div className="col-12">
                                    <div className="d-flex align-center justify-between">
                                        <p className="m-0 pe-3 text-muted">Amount</p>
                                        <p className="m-0">Rs. {data["amount"]}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {data["service_tax"] != 0 && data["service_tax"] && (
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="d-flex align-center justify-between">
                                <p className="m-0 pe-3 text-muted">Service Tax</p>
                                <p className="m-0">{data["service_tax"]}</p>
                            </div>
                        </div>
                    </div>
                )}

                {location.pathname === "/pay-emi" && (
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="d-flex align-center justify-between border-bottom border-top border-1 pt-2 pb-2">
                                <p className="m-0 pe-3 tx-16 text-black">Total Amount</p>
                                <p className="m-0 tx-16 text-black">Rs. {data.amount}</p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row mb-3 mt-5">
                    <div className="col-12">
                        <button onClick={BillPaySixClickOpen} type="submit" className="btn btn-primary w-100">
                            Pay Now
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PayEMiDetails;
