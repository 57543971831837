import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { clearNotificationPost, seenNotificationPost } from "../API";

// Images
import AxisbankIcon from "../Static/Images/axisbank.webp";
import SingleNotification from "./SingleNotification";
import {getBaseUrl} from "../API/getBaseUrl";

function Notification(props) {
  // mobile number
  const mobileNumber = localStorage.getItem("MobileNumber");
  // notification
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    fetch(
      `${getBaseUrl}/shownotification/?phone_number=${mobileNumber}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      }
    )
      .then((res) => res.json())
      .then(async (data) => {
        if (data?.error === true) {
          alert(data?.message);
        } else {
          localStorage.setItem("notification", JSON.stringify(data.data));
          setNotifications(data.data);
          let newArray = [];
          data.data?.map((data) => {
            const demo = Array.from([data.id]);
            newArray.push(demo.toString());
          });

          const body = {
            notification_id: newArray,
          };

          const res = await seenNotificationPost(body);
        }
      });
  }, []);

  const handleClear = async (name, id) => {
    let newArray = [];
    if (name === "single_clear") {
      const covertString = id.toString();
      const demo = [...covertString];
      newArray = demo;
    } else if (name === "all_clear") {
      const notification = JSON.parse(localStorage.getItem("notification"));
      notification?.map((data) => {
        const demo = Array.from([data.id]);
        newArray.push(demo.toString());
      });
    }
    const body = {
      notification_id: newArray,
    };

    const data = await clearNotificationPost(body);
    if (data.error) {
      swal("Sorry", `${data?.message}!`, "error");
    } else {
      swal(" ", `${data?.message}!`, "success").then(() => {
        localStorage.removeItem("notification");
        window.location.reload();
      });
    }
  };

  return (
    <>
      <div className="h-100">
        <div className="side-pane">
          <div className="d-flex align-center justify-between side-pane-header pb-0">
            <div className="d-flex align-center">
              <Link to="/home">
                <svg
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="#1A1A1A" fillRule="evenodd">
                    <path d="M23.25 14.531a.969.969 0 1 1 0 1.938H7.75a.969.969 0 0 1 0-1.938h15.5z" />
                    <path d="m9.12 15.5 5.128 5.127a.969.969 0 0 1-1.37 1.37l-5.813-5.812a.969.969 0 0 1 0-1.37l5.812-5.813a.969.969 0 1 1 1.37 1.37L9.12 15.5z" />
                  </g>
                </svg>
              </Link>
            </div>
            <div>
              <p className="mb-1 tx-16">
                <strong>Notifications</strong>
              </p>
            </div>
            <div>
              <button
                onClick={() => handleClear("all_clear")}
                className="btn btn-outline-secondary"
              >
                Clear All
              </button>
            </div>
          </div>
        </div>
        <div className="home-base home-base-notification h-100">
          <div className="h-100 py-4 px-3">
            <div className="py-0">
              <p className="mb-2 tx-16">Today</p>
            </div>
            {notifications?.map((data) => (
              <SingleNotification
                key={data.id}
                notifications={data}
                handleClear={handleClear}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification;
