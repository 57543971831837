import React from "react";

const BillDetails = ({ data }) => {
  return (
    <>
      {data?.RespBillPeriod && data?.RespBillPeriod != 0 && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Bill period</p>
              <p className="m-0">
                <strong>{data?.RespBillPeriod}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {data?.bill_date && data?.bill_date != 0 && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Bill date</p>
              <p className="m-0">
                <strong>{data?.bill_date}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {data?.bill_number && data?.bill_number != 0 && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Bill number</p>
              <p className="m-0">
                <strong>{data?.bill_number}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {data["billerId"] && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Biller ID</p>
              <p className="m-0">
                <strong>{data["billerId"]}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {data["amount"] != 0 && data["amount"] && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Total Amount</p>
              <p className="m-0">
                <strong>Rs. {data["amount"]}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {data["transation_status"] && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Transaction status</p>
              <p className="m-0">
                {data["transation_status"] == "SU"
                  ? "Success"
                  : data["transation_status"] == "FA"
                  ? "Failure"
                  : "Processing"}
              </p>
            </div>
          </div>
        </div>
      )}
      {data["transaction_id"] != 0 && data["transaction_id"] && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Transaction ID</p>
              <p className="m-0">
                <strong>{data["transaction_id"]}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {data["transaction_date_and_time"] && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Transaction date & time</p>
              <p className="m-0">
                <strong>{data["transaction_date_and_time"]}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {data["approval_number"] && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Approval Number</p>
              <p className="m-0">
                <strong>{data["approval_number"]}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="row mb-3">
        <div className="col-12">
          <div className="border-bottom-dash"></div>
        </div>
      </div>
      {data["customer_name"] && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Customer name</p>
              <p className="m-0">
                <strong>{data["customer_name"]}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {data["customer_mobile"] && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Customer number</p>
              <p className="m-0">
                <strong>{data["customer_mobile"]}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {data["initiation_channel"] && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Initiating channel</p>
              <p className="m-0">
                <strong>{data["initiation_channel"]}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {data["payment_mode"] && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Payment mode</p>
              <p className="m-0">
                <strong>{data["payment_mode"]}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {data?.amount != 0 && data?.amount && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Bill Amount</p>
              <p className="m-0">
                <strong>Rs. {data?.amount}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {data["customer_convinience_fees"] &&
        data["customer_convinience_fees"] != 0 && (
          <div className="row mb-3">
            <div className="col-12">
              <div className="d-flex align-center justify-between">
                <p className="m-0 pe-3 text-muted">Customer Convinience Fee</p>
                <p className="m-0">
                  <strong>Rs.{data["customer_convinience_fees"]}</strong>
                </p>
              </div>
            </div>
          </div>
        )}
      {data["service_tax"] != 0 && data["service_tax"] && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3 text-muted">Service Tax</p>
              <p className="m-0">
                <strong>{data["service_tax"]}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
      {data["amount"] != 0 && data["amount"] && (
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-center justify-between">
              <p className="m-0 pe-3">
                <strong>Total Amount</strong>
              </p>
              <p className="m-0">
                <strong>Rs. {data["amount"]}</strong>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BillDetails;
