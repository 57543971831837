import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import moment from "moment";
import Fail from "../Static/Images/order-fail.gif";
import { getProccFailData } from "../API";
import GooglePlay from "../Static/Images/google-play-logo.webp";
import { Link, useHistory } from "react-router-dom";

const PayemiTransFailed = () => {
  const history = useHistory();
  const [eDetail, setEDetail] = useState([]);
  const successData = JSON.parse(localStorage.getItem("TransData"));
  const biId = JSON.parse(localStorage.getItem("TransBillId"));
  //date
  const getDateValue = (dd) => {
    return new Date(dd);
  };
  useEffect(() => {
    fetchEDetail(biId);
  }, []);
  const fetchEDetail = async (biId) => {
    const response = await getProccFailData(biId);
    setEDetail(response?.data);
  };

  return (
    <>
      <div className="side-pane side-pane-second">
        <AppBar className="side-pane-header">
          <div
            onClick={() => {
              history.push("transaction-history");
            }}
            style={{ marginTop: "-10px", marginBottom: "-10px" }}
          >
            <svg
              width="21 "
              height="21 "
              viewBox="0 0 21 21 "
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.928 3.072c-4.095-4.096-10.76-4.096-14.856 0s-4.096 10.76 0 14.856 10.76 4.096 14.856 0 4.096-10.76 0-14.856zm-3.285 11.57a.808.808 0 0 1-1.143 0l-3-3-3.142 3.143a.808.808 0 1 1-1.143-1.143L9.357 10.5l-3-3A.808.808 0 1 1 7.5 6.357l3 3L13.357 6.5A.808.808 0 1 1 14.5 7.643L11.643 10.5l3 3a.808.808 0 0 1 0 1.142z"
                fill="#000"
                fillRule="nonzero"
                opacity=".495"
              />
            </svg>
          </div>
        </AppBar>
        <div className="py-0 px-3">
          <div
            className="container-fluid"
            style={{ marginTop: "30px", marginRight: "-5px" }}
          >
            <div className="mt-0">
              <div className="row mb-3">
                <div className="col-12">
                  <p className="text-center" style={{ marginBottom: "-10px" }}>
                    <img src={Fail} style={{ width: "90px" }} />
                  </p>
                  <p className="mb-4 tx-23 text-center text-danger">
                    Payment Failed
                  </p>
                  <p className="mb-0 text-center text-black">
                    {successData.biller_name}
                  </p>
                  <p className="mb-4 text-center text-muted">
                    {
                      JSON.parse(localStorage.getItem("home_bill_info"))
                        ?.loan_type
                    }
                    {"  "} Loan
                  </p>
                  <div className="w-auto d-flex align-center justify-center mb-2">
                    <p className="tx-23 mb-0 me-2 text-muted">&#8377;</p>
                    <p className="tx-60 mb-0 me-2 text-black weight-300 lh-1">
                      {successData.amount}
                    </p>
                  </div>
                  <p className="mb-0 text-center text-black">
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="M6.761 17.647a7.703 7.703 0 0 1-.002-10.893 7.703 7.703 0 0 1 10.893.002 7.703 7.703 0 0 1 .002 10.893 7.703 7.703 0 0 1-10.893-.002z"
                          fill="#D41700"
                        />
                        <path
                          d="M13.691 9.726a.7.7 0 0 1 .99.99l-3.96 3.96a.7.7 0 0 1-.99-.99l3.96-3.96z"
                          fill="#FFF"
                        />
                        <path
                          d="M9.73 10.715a.7.7 0 1 1 .99-.99l3.962 3.962a.7.7 0 1 1-.99.99L9.73 10.715z"
                          fill="#FFF"
                        />
                      </g>
                    </svg>
                    Failed |{" "}
                    {moment().utcOffset("+05:30").format("DD MMM YY hh:mm a")}
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <button type="submit" className="btn btn-primary w-100">
                    Try Again
                  </button>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="tx-16 text-black">Receipt</div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Biller name</p>
                    <p className="m-0">
                      <strong>{successData.biller_name}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Bill period</p>
                    <p className="m-0">
                      <strong>
                        {moment(
                          getDateValue(successData.transaction_datetime)
                        ).format("DD MMM")}
                        -{moment().utcOffset("+05:30").format("DD MMM")}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Bill date</p>
                    <p className="m-0">
                      <strong>
                        {moment(
                          getDateValue(successData.transaction_datetime)
                        ).format("DD MMM YY ")}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Bill number</p>
                    <p className="m-0">
                      <strong>{successData.bill_id}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Biller ID</p>
                    <p className="m-0">
                      <strong>{successData.biller_id}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Total Amount</p>
                    <p className="m-0">
                      <strong>Rs. {successData.amount}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Transaction status</p>
                    <p className="m-0">
                      <strong>{successData.razorpay_transaction_status}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Transaction ID</p>
                    <p className="m-0">
                      <strong>{successData.razorpay_transaction_id}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">
                      Transaction date & time
                    </p>
                    <p className="m-0">
                      <strong>
                        {" "}
                        {moment(
                          getDateValue(successData.transaction_datetime)
                        ).format("DD MMM YY | hh:mm a")}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Approval Number</p>
                    <p className="m-0">
                      <strong>{eDetail[0]?.approval_number}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="border-bottom-dash"></div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Customer name</p>
                    <p className="m-0">
                      <strong>{successData.customer_name}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Customer number</p>
                    <p className="m-0">
                      <strong>{eDetail[0]?.customer_mobile}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Initiating channel</p>
                    <p className="m-0">
                      <strong>{eDetail[0]?.initiation_channel}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Payment mode</p>
                    <p className="m-0">
                      <strong>{eDetail[0]?.payment_mode}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Bill Amount</p>
                    <p className="m-0">
                      <strong>Rs. {eDetail[0]?.amount}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">
                      Customer Convinience Fee
                    </p>
                    <p className="m-0">
                      <strong>
                        Rs.{eDetail[0]?.customer_convinience_fees}
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Service Tax</p>
                    <p className="m-0">
                      <strong>Rs.{eDetail[0]?.service_tax}</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3">
                      <strong>Total Amount</strong>
                    </p>
                    <p className="m-0">
                      <strong>Rs. {eDetail[0]?.amount}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-5 mt-4 pt-0">
              <div className="col-6"></div>
              <div className="col-6">
                <Link to="/register-complaint">
                  <button
                    type="submit"
                    className="btn btn-outline-primary w-100"
                  >
                    Having Issue
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PayemiTransFailed;
