import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
// Images
import BharatBillpay from "../Static/Images/bharat-billpay.png";
import ScratchCard from "../Static/Images/scratch-card.png";
import ScratchHalf from "../Static/Images/scratch-half.png";
import DoneIcon from "../Static/Images/success.gif";
import GooglePlay from "../Static/Images/google-play-logo.webp";

import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Slide from "@mui/material/Slide";
import { useGlobalContext } from "../context";
import { useHistory } from "react-router-dom";
import BelowIcon from "../Components/BelowIcon";
import { getRecieptApi } from "../API";
import BillDetails from "../Components/BillDetails";
import {getBaseUrl} from "../API/getBaseUrl";
var fileDownload = require("js-file-download");
const TransitionUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PayEmiSuccess = (props) => {
  const { BillPaySevenopen, BillPaySevenClose } = useGlobalContext();
  //biller info
  const [billerInfo, setBillerInfo] = useState([]);
  const [cashback, setCashback] = useState();
  const [OtpOpen, setOtpOpen] = React.useState(false);
  const [isshowActive, setshowActive] = useState("false");
  const [CashbackAmount, setCashbackAmount] = useState();
  // get data from localstorage

  const data = JSON.parse(localStorage.getItem("fetch_bill_data"));
  const userPaymentInfo = data?.payload;
  const amount = localStorage.getItem("amount");
  const id = userPaymentInfo?.map((data) => data?.profile_id);
  const [cashbackStatus, setCashbackStatus] = useState(true);
  //  biller id
  const biller_id = userPaymentInfo?.map((data) => data?.id);

  useEffect(() => {
    if (biller_id) {
      fetch(`${getBaseUrl}getbilldetails/?bill_id=${biller_id}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.error) {
            console.log(data);
          } else {
            setBillerInfo(data?.data);
            setCashbackStatus(data?.data[0].cashback_flag);
          }
        });
    }
  }, []);

  const handleOpen = () => {
    setOtpOpen(true);
  };

  const handleshowToggle = () => {
    setshowActive(!isshowActive);
    const url = `${getBaseUrl}getcashback/?bill_id=${biller_id}&profile_id=${id}`;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCashbackAmount(data.cashback_amount);
        console.log(data);
        setCashback(data);
      });
    console.log("user click the");
  };
  let history = useHistory();

  const handleRedem = () => {
    fetch(`${getBaseUrl}ScratchCard/?id=${id}&bill_id=${biller_id}`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        history.push("/home");
        console.log(data?.message);
      });
  };

  const handleCancel = () => {
    history.push("/home");
  };
  const handleClose = () => setOtpOpen(false);
  const [link, setLink] = useState("");

  // payment reciept
  const handleDownload = async (id) => {
    fetch(`${getBaseUrl}getPaymentReceiptPDF/?bill_id=${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        fileDownload(data?.file_url, "payment_receipt.pdf");
      });
  };
  return (
    <>
      {/* Bill Pay Step Seven */}
      <Dialog
        fullScreen
        open={true}
        onClose={BillPaySevenClose}
        TransitionComponent={TransitionUp}
        className="side-pane side-pane-second"
      >
        <AppBar sx={{ position: "relative" }} className="side-pane-header">
          <div className="d-flex align-center justify-between"></div>
        </AppBar>

        {billerInfo === undefined ? (
          <p>no data showen</p>
        ) : (
          billerInfo?.map((data) => (
            <div className="py-0 px-3">
              <div className="container-fluid">
                <div className="mt-4">
                  <div className="row mb-5">
                    <div className="col-12">
                      <p className="text-center">
                        <img src={DoneIcon} className="w-50" />
                      </p>
                      <p className="mb-4 tx-23 text-center text-success">
                        Bill Payment Successful
                      </p>
                      <p className="mb-0 text-center text-black">
                        To{" "}
                        {
                          JSON.parse(localStorage.getItem("currentBankBranch"))
                            ?.billerName
                        }{" "}
                        Finance Bank Limited
                      </p>
                      <p className="mb-4 text-center text-muted">CAR Loan</p>
                      <div className="w-auto d-flex align-center justify-center mb-2">
                        <p className="tx-23 mb-0 me-2 text-muted">&#8377;</p>
                        <p className="tx-60 mb-0 me-2 text-black weight-300 lh-1">
                          {amount}
                        </p>
                      </div>
                      <p className="mb-0 text-center text-black">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                          className="me-2"
                        >
                          <path
                            d="M6 0a6 6 0 1 0 6 6 6.006 6.006 0 0 0-6-6zm2.871 4.94-3.09 3.09a.75.75 0 0 1-1.061 0L3.129 6.44a.75.75 0 0 1 1.06-1.061l1.061 1.06 2.56-2.56a.75.75 0 0 1 1.061 1.06z"
                            fill="#0B7C05"
                            fillRule="nonzero"
                          />
                        </svg>
                        Completed | {data["transaction_date_and_time"]}
                      </p>
                    </div>
                  </div>
                  {cashbackStatus === true ? (
                    <div className="row mb-5">
                      <div className="col-12">
                        <div className="card-box">
                          <div className="tx-16">Scratch Now</div>
                          <div className="scratch-box">
                            <img src={ScratchHalf} onClick={handleOpen} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="row mb-3">
                    <div className="col-12">
                      <div className="tx-16 text-black">Receipt</div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12">
                      <div className="d-flex align-center justify-between">
                        <p className="m-0 pe-3 text-muted">Biller name</p>
                        <p className="m-0">
                          <strong>{data?.customer_name}</strong>
                        </p>
                      </div>
                    </div>
                  </div>

                  <BillDetails data={data} />

                  <div className="row mb-5 mt-4 pt-0">
                    <div className="col-6">
                      <button
                        type="submit"
                        className="btn btn-outline-primary w-100"
                        onClick={() => (window.location.href = "/home")}
                      >
                        Proceed to home
                      </button>
                    </div>
                    <div className="col-6">
                      <a
                        type="submit"
                        className="btn btn-outline-primary w-100"
                        onClick={() => handleDownload(data?.id)}
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="#412E8D" fillRule="evenodd">
                            <path d="M4.125 14.438a.687.687 0 1 1 1.375 0V16.5c0 .38.308.688.688.688h9.625c.38 0 .687-.308.687-.688v-2.063a.687.687 0 1 1 1.375 0V16.5c0 1.14-.923 2.063-2.063 2.063H6.188A2.062 2.062 0 0 1 4.124 16.5v-2.063z" />
                            <path d="M13.264 10.514a.687.687 0 1 1 .972.972l-2.75 2.75a.687.687 0 0 1-.972 0l-2.75-2.75a.687.687 0 1 1 .972-.972L11 12.778l2.264-2.264z" />
                            <path d="M10.313 4.125a.687.687 0 1 1 1.374 0v9.625a.687.687 0 1 1-1.374 0V4.125z" />
                          </g>
                        </svg>
                        Download
                      </a>
                    </div>
                  </div>

                  <BelowIcon />
                </div>
              </div>
            </div>
          ))
        )}
      </Dialog>
      {/* OTP */}
      <Modal
        open={OtpOpen}
        onClose={handleClose}
        className="modal-alert"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-wrap scratch-modal p-0 text-center">
          <div className={isshowActive ? "d-block" : "d-none"}>
            <img src={ScratchCard} onClick={handleshowToggle} />
          </div>
          <div className={isshowActive ? "d-none" : "d-block py-3 px-3"}>
            {CashbackAmount > 0 ? (
              <>
                <h2>You Won</h2>
                <h1>{cashback?.cashback_amount}</h1>
                <p className="tx-18 text-muted">
                  Use this cashback to pay your other EMIs and also earn
                  cashback on next EMI payments
                </p>
                <a
                  className="text-white btn btn-primary w-100"
                  onClick={handleRedem}
                >
                  Redem Now
                </a>
              </>
            ) : (
              <>
                <h4>Better Luck Next Time</h4>
                <a
                  className="text-white btn btn-primary w-100"
                  onClick={handleCancel}
                >
                  Go to your account
                </a>
              </>
            )}
          </div>

          <div className="mo-close">
            <a onClick={handleClose} type="submit" className="btn btn-link">
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.928 3.072c-4.095-4.096-10.76-4.096-14.856 0s-4.096 10.76 0 14.856 10.76 4.096 14.856 0 4.096-10.76 0-14.856zm-3.285 11.57a.808.808 0 0 1-1.143 0l-3-3-3.142 3.143a.808.808 0 1 1-1.143-1.143L9.357 10.5l-3-3A.808.808 0 1 1 7.5 6.357l3 3L13.357 6.5A.808.808 0 1 1 14.5 7.643L11.643 10.5l3 3a.808.808 0 0 1 0 1.142z"
                  fill="#696969"
                  fillRule="nonzero"
                />
              </svg>
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PayEmiSuccess;
