import React, { Fragment, useEffect, useState } from "react";
import Add from "../Static/Images/add.webp";
import { getProfileMobile, getMissingInfoData } from "../API";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import PayNow from "./PayNow";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MainMenu from "../../src/Components/MainMenu";
import BharatBillpayw from "../Static/Images/bharat-billpay-w.webp";
import HeaderShadow from "../Static/Images/header-shadow.png";
import HdfcClient from "../Static/Images/hdfc-client.webp";
import IciciClient from "../Static/Images/icici-client.webp";
import TvsClient from "../Static/Images/tvs-client.webp";
import EdClient from "../Static/Images/ed-client.webp";
import PeSpinner from "../Components/PeSpinner";
import { useHistory } from "react-router-dom";
import { getLoanCatData } from "../API";

const TransitionUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HomeBankPayEmi = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [homeData, setHomeData] = useState([]);
  const [catName, setCatName] = useState([]);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("");
  const [count, setCount] = useState();

  //missing data
  const [bankName, setBankName] = useState([]);
  const [billId, setBillId] = useState([]);
  const [lacno, setLacno] = useState([]);
  const [lamt, setLamt] = useState("");
  const [eamt, setEamt] = useState("");
  const [loanType, setLoanType] = useState("");
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const yearM = new Date().getFullYear();
  useEffect(() => {
    fetchCatData();
  }, []);

  const fetchCatData = async () => {
    const response = await getLoanCatData();
    if (response !== null) {
      setCatName(response);
      setLoading(false);
    }
  };

  const [openq, setOpenq] = React.useState(false);

  const [open1, setOpen1] = useState(true);
  const handleDrawerOpen = () => {
    setOpenq(true);
    setOpen1(false);
  };
  const handleDrawerClose = () => {
    setOpenq(false);
    setOpen1(true);
  };

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const profile = localStorage.getItem("profile");
  const mNumber = localStorage.getItem("MobileNumber");

  useEffect(() => {
    fetchProfileData();
  }, [mNumber, profile]);

  const fetchProfileData = async () => {
    if ((mNumber, profile)) {
      const response = await getProfileMobile(mNumber, profile);
      setCount(response?.new_notification_count);
      setHomeData(response?.data);
      setErr(response?.error);
      setMsg(response?.message);
      setLoading(false);
    }
  };
  const numberWithCommas = (n) => {
    var parts = n?.toString().split(".");
    if (parts)
      return (
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        (parts[1] ? "." + parts[1] : "")
      );
  };
  function abbreviateNumber(number, decimals, recursiveCall) {
    const decimalPoints = decimals || 2;
    const noOfLakhs = number / 100000;
    let displayStr;
    let isPlural;

    // Rounds off digits to decimalPoints decimal places
    function roundOf(integer) {
      return +integer.toLocaleString(undefined, {
        minimumFractionDigits: decimalPoints,
        maximumFractionDigits: decimalPoints,
      });
    }

    if (noOfLakhs < 1) {
      displayStr =
        Math.abs(number) > 999
          ? Math.sign(number) * (Math.abs(number) / 1000).toFixed(1) + "K"
          : Math.sign(number) * Math.abs(number);
    } else if (noOfLakhs >= 1 && noOfLakhs <= 99) {
      const lakhs = roundOf(noOfLakhs);
      isPlural = lakhs > 1 && !recursiveCall;
      displayStr = `${lakhs} L`;
    } else if (noOfLakhs >= 100) {
      const crores = roundOf(noOfLakhs / 100);
      const crorePrefix =
        crores >= 100000 ? abbreviateNumber(crores, decimals, true) : crores;
      isPlural = crores > 1 && !recursiveCall;
      displayStr = `${crorePrefix} C`;
    } else {
      displayStr = roundOf(+number);
    }

    return displayStr;
  }

  // Add Missing Info
  const [Missinginfo, MissinginfosetOpen] = React.useState(false);
  const MissinginfoClickOpen = (detail) => {
    setBankName(detail?.biller__billerName);
    setLacno(detail?.loan_acc_no);
    setBillId(detail?.biller__billerId);
    MissinginfosetOpen(true);
  };
  const handleTransction = (data) => {
    localStorage.setItem("home_bill_info", JSON.stringify(data));
    history.push("/transaction-history");
  };
  const MissinginfoClose = () => {
    MissinginfosetOpen(false);
  };

  const saveData = async () => {
    if (month == "Months") month = "";
    let dd = year;
    await getMissingInfoData(lacno, loanType, lamt, eamt, month, dd);
    await MissinginfoClose();
    await fetchProfileData();
  };

  const PayDetail = (val) => {
    localStorage.setItem("homedata", JSON.stringify(val));
    history.push("dashboard");
  };
  const backToCate = () => {
    history.push("Category");
  };
  const backToNoti = () => {
    history.push("notification");
  };
  return (
    <>
      <Box sx={{ display: "flex" }} className="menu-sidebar">
        <Drawer
          sx={{
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={openq}
        >
          <MainMenu />
        </Drawer>
      </Box>
      <div className={open1 ? "menu-close" : "menu-open"}>
        <div className="home-main">
          <div className="drawer-toggle">
            <a onClick={handleDrawerClose}></a>
          </div>
          <div className="d-flex align-center justify-between home-header px-4">
            <div className="d-flex align-center position-relative head-info">
              <div className="me-2">
                <a onClick={handleDrawerOpen}>
                  <svg
                    width="37"
                    height="37"
                    viewBox="0 0 37 37"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="#FFF" fillRule="evenodd">
                      <path d="M11.563 12.719a1.156 1.156 0 0 1 0-2.313h15.03a1.156 1.156 0 0 1 0 2.313h-15.03zM11.563 19.656a1.156 1.156 0 0 1 0-2.312h15.03a1.156 1.156 0 0 1 0 2.312h-15.03zM11.563 26.594a1.156 1.156 0 0 1 0-2.313h15.03a1.156 1.156 0 0 1 0 2.313h-15.03z" />
                    </g>
                  </svg>
                </a>
              </div>
              <h1>PayEMI</h1>
              <div className="ms-1">
                <img src={BharatBillpayw} />
              </div>
            </div>
            <div className="d-flex align-center position-relative head-info">
              <div onClick={() => backToNoti()}>
                {count == 0 ? (
                  <></>
                ) : (
                  <span className="text-light">{count}</span>
                )}
                <svg
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.313 17.438v-4.844a5.812 5.812 0 0 0-11.625 0v4.844c0 .705-.19 1.367-.519 1.937h12.662a3.857 3.857 0 0 1-.518-1.938zm3.875 3.875H5.813c-1.292 0-1.292-1.938 0-1.938 1.07 0 1.937-.867 1.937-1.938v-4.843a7.75 7.75 0 0 1 15.5 0v4.844c0 1.07.867 1.937 1.938 1.937 1.291 0 1.291 1.938 0 1.938zm-7.174 3.392a2.906 2.906 0 0 1-5.028 0 .969.969 0 0 1 .838-1.455h3.352a.969.969 0 0 1 .838 1.455z"
                    fill="#FFF"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
              <a className="ms-3">
                <img src={Add} onClick={() => backToCate()} />
              </a>
            </div>
            <div className="header-shadow">
              <img src={HeaderShadow} />
            </div>
          </div>
          <div className="home-base">
            <div className="home-content px-3">
              <div className="row">
                {err === false ? (
                  homeData?.length > 0 ? (
                    <>
                      {homeData?.map((item, index) => {
                        var per = 0 + "%";
                        if (item?.loan_paid)
                          per =
                            (100 * parseFloat(item?.loan_paid)) /
                              parseFloat(item?.loan_amount) +
                            "%";
                        return (
                          <div className="col-12">
                            <div className="home-card cursor-pointor">
                              <div className="d-flex justify-between mb-3">
                                <div
                                  className="d-flex align-center "
                                  onClick={() => handleTransction(item)}
                                >
                                  <img src={item?.biller__logo_url} />
                                  <div>
                                    <p className="mb-0">
                                      {item?.biller__billerName}
                                      <br />
                                      <span>{item?.loan_type} </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="PendEmi">
                                  <p className="mb-0 text-black">
                                    <strong>
                                      Rs.
                                      {numberWithCommas(item?.Amount)}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                              <>
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                      background: "#3a2d78",
                                      width: per,
                                    }}
                                    aria-valuenow="52"
                                    aria-valuemin="100"
                                    aria-valuemax="100"
                                  >
                                    &#8377; {abbreviateNumber(item?.loan_paid)}
                                  </div>
                                </div>

                                <p className="text-end">
                                  &#8377; {abbreviateNumber(item?.loan_amount)}
                                </p>
                                <hr />
                              </>

                              <button
                                type="submit"
                                onClick={() => {
                                  PayDetail(item);
                                }}
                                className="btn btn-primary w-100"
                              >
                                Pay Now
                              </button>
                              {!item?.emi && (
                                <>
                                  <div className="mb-0 mt-3">
                                    <p className="mb-0 text-center">
                                      <a
                                        onClick={() =>
                                          MissinginfoClickOpen(item)
                                        }
                                        style={{ color: "blue" }}
                                      >
                                        <strong>Add Missing Information</strong>
                                      </a>
                                    </p>
                                  </div>

                                  <Dialog
                                    fullScreen
                                    open={Missinginfo}
                                    onClose={MissinginfoClose}
                                    TransitionComponent={TransitionUp}
                                    className="modal-half"
                                  >
                                    <AppBar
                                      sx={{ position: "relative" }}
                                      className="modal-half-header"
                                    >
                                      <div className="text-center">
                                        <div onClick={MissinginfoClose}>
                                          <svg
                                            width="27"
                                            height="27"
                                            viewBox="0 0 27 27"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M23.05 3.95c-5.265-5.266-13.834-5.267-19.1 0-5.267 5.266-5.266 13.834 0 19.1 5.265 5.267 13.834 5.267 19.1 0 5.266-5.266 5.266-13.835 0-19.1zm-4.224 14.876a1.039 1.039 0 0 1-1.469 0L13.5 14.969l-4.04 4.04a1.039 1.039 0 1 1-1.47-1.469l4.041-4.04-3.857-3.857a1.039 1.039 0 1 1 1.469-1.47l3.857 3.858 3.673-3.673a1.039 1.039 0 1 1 1.47 1.469L14.968 13.5l3.857 3.857a1.039 1.039 0 0 1 0 1.469z"
                                              fill="#E6E6E6"
                                              fillRule="nonzero"
                                              opacity=".495"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </AppBar>
                                    <div className="modal-half-content pb-4 px-4">
                                      <div className="py-0 mb-4">
                                        <p className="mb-1 tx-18 font-500">
                                          Add missing information for
                                        </p>
                                        <p className="mb-0 tx-12 text-black">
                                          {bankName} - {billId}
                                        </p>
                                      </div>
                                      <div className="row mb-4">
                                        <div className="col-12">
                                          <label
                                            for="Type"
                                            className="col-form-label pt-0"
                                          >
                                            Loan Type
                                          </label>
                                          <select
                                            className="form-select"
                                            onChange={(e) =>
                                              setLoanType(e.target.value)
                                            }
                                          >
                                            <option selected disabled value="">
                                              Select
                                            </option>
                                            {catName.map((item, index) => {
                                              return (
                                                <option
                                                  value={item?.category_name}
                                                >
                                                  {item?.category_name}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="row mb-4 position-relative mobile-otp">
                                        <div className="col-12">
                                          <label
                                            for="Loan"
                                            className="col-form-label pt-0"
                                          >
                                            Loan Amount
                                          </label>
                                          <input
                                            placeholder="Enter Your total Loan Amount"
                                            name="Loan"
                                            type="text"
                                            className="form-control"
                                            value={lamt}
                                            onChange={(e) =>
                                              setLamt(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="row mb-4 position-relative mobile-otp">
                                        <div className="col-12">
                                          <label
                                            for="EMI"
                                            className="col-form-label pt-0"
                                          >
                                            EMI
                                          </label>
                                          <input
                                            name="EMI"
                                            type="text"
                                            className="form-control"
                                            value={eamt}
                                            onChange={(e) =>
                                              setEamt(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="row mb-4 position-relative mobile-otp">
                                        <div className="col-12">
                                          <label
                                            for="Type"
                                            className="col-form-label pt-0"
                                          >
                                            Date
                                          </label>
                                        </div>
                                        <div className="col-6">
                                          <select
                                            className="form-select"
                                            defaultValue="January"
                                            onChange={(e) =>
                                              setMonth(e.target.value)
                                            }
                                          >
                                            <option>Months</option>
                                            {months.map((option, index) => (
                                              <option
                                                key={index}
                                                value={index + 1}
                                              >
                                                {option}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                        <div className="col-6">
                                          <select
                                            className="form-select"
                                            onChange={(e) =>
                                              setYear(e.target.value)
                                            }
                                          >
                                            <option>Year</option>
                                            {Array.from(
                                              new Array(20),
                                              (v, i) => (
                                                <option
                                                  key={i}
                                                  value={yearM - i}
                                                >
                                                  {yearM - i}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="row mb-4 position-relative mobile-otp">
                                        <div className="col-12">
                                          <button
                                            type="submit"
                                            className="btn btn-primary w-100"
                                            onClick={saveData}
                                          >
                                            Update
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </Dialog>
                                </>
                              )}
                            </div>
                            {/* Card End */}
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <> </>
                  )
                ) : (
                  <>
                    {" "}
                    <p1 style={{ color: "Red", fontSize: "20px" }}>
                      {" "}
                      [....{msg}]
                    </p1>{" "}
                  </>
                )}
                {loading ? (
                  <PeSpinner />
                ) : homeData?.length === 0 ? (
                  <h3 className="text-center w-100" style={{ color: "Black" }}>
                    No Records Found....
                  </h3>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBankPayEmi;
