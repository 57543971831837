import React, { useState, useEffect } from "react";

// Images
import BharatBillpay from "../Static/Images/bharat-billpay.png";
// import AxisHeader from "../Static/Images/axis-header.webp";
// import Hdfc from "../Static/Images/hdfc.webp";
// import DoneIcon from "../Static/Images/done-icon.webp";
// import GooglePlay from "../Static/Images/google-play-logo.webp";

import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Slide from "@mui/material/Slide";
//
// import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../context";
import PeSpinner from "../Components/PeSpinner";
import PayEMiDetails from "../Components/PayEMiDetails";
import {getBaseUrl} from "../API/getBaseUrl";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});
const TransitionUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function SubDomainPayEmi(props) {
    const directUserInfo = props.data;
    const exactness = props.exactness;
    // profile id
    const profile_id = props.profileId;
    const { BillPaySevensetOpen, BillPaySixopen, BillPaySixsetOpen } = useGlobalContext();

    {
        /* Bill Pay Step Six */
    }

    const BillPaySixClose = () => {
        BillPaySixsetOpen(false);
    };
    // localstorage
    const data = localStorage.getItem("guest-fetch-bill-data");
    const fetchBill = JSON.parse(data);
    // bil_id
    const bill_id = fetchBill?.map((data) => data.id);
    // bill payment
    const bill_payement_json = localStorage.getItem("guest_biller_payment");
    const bill_payement = JSON.parse(bill_payement_json);

    const maxAmount = bill_payement?.maxAmount;
    const minAmount = bill_payement?.minAmount;
    {
        /* Bill Pay Step Seven */
    }
    const [inputAmount, setInputAmount] = useState();
    const [amountError, setAmountError] = useState("");
    const [emi, setEmi] = useState();
    const amount = props.amount;
    let totalAmount = amount;

    useEffect(() => {
        if (exactness == "EXACT") {
            setInputAmount(totalAmount);
        } else if (!exactness) {
            setInputAmount(inputAmount);
        } else if (exactness == "EXACT_DOWN") {
            setInputAmount(totalAmount);
        } else if (exactness == "EXACT_UP") {
            setInputAmount(inputAmount);
        } else {
            setInputAmount(inputAmount);
        }
    }, [exactness]);

    let history = useHistory();

    const [error, setError] = useState();
    const [remark, setRemark] = useState("");
    const BillPaySevenClickOpen = (e) => {
        fetch(`${getBaseUrl}CreateOrder-noauth/?id=${profile_id}&bill_id=${bill_id}&amount=${inputAmount}&notes=${remark.replace(/ /g, "")}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data?.error) {
                    setError(data?.message);
                } else {
                    // razor pay
                    const options = {
                        key: "rzp_live_ZtOSmut8p6fuCX",
                        // key: "rzp_test_I4yUvwRiUPNWay",
                        amount: inputAmount,
                        currency: "INR",
                        name: `${directUserInfo?.map((data) => data.customer_name)}`,
                        order_id: `${data?.order_id}`,
                        handler: function (response) {
                            BillPaySevensetOpen(true);
                            localStorage.setItem("amount", inputAmount);
                            // window.location.href = "/processing ";
                            history.push("/processing");
                        },
                        notes: {
                            address: "Razorpay Corporate Office",
                        },
                        theme: {
                            color: "#3399cc",
                        },
                        retry: {
                            enabled: false,
                        },
                    };
                    let rzp1 = new window.Razorpay(options);

                    rzp1.on("payment.failed", function (response) {
                        history.push("/failure");
                        localStorage.setItem("amount", inputAmount);
                    });

                    if (inputAmount < minAmount / 100) {
                        setAmountError(`your amount should be greater than ${minAmount / 100} `);
                    } else if (maxAmount / 100 < inputAmount) {
                        setAmountError(`your amount should be greater than and less than ${maxAmount}`);
                    } else {
                        if (exactness == "EXACT_DOWN") {
                            if (inputAmount > totalAmount) {
                                setAmountError(`Your Value less than ${totalAmount}`);
                                setError("");
                            } else {
                                rzp1.open();
                                setAmountError("");
                                setError("");
                            }
                        } else if (exactness == "EXACT_UP") {
                            if (inputAmount < totalAmount) {
                                setAmountError(`Your Value increase than ${totalAmount}`);
                                setError("");
                            } else {
                                rzp1.open();
                                setAmountError("");
                                setError("");
                            }
                        }
                    }
                }
            });

        e.preventDefault();
    };

    const billerInfo = props.billerInfo;
    return (
        <>
            <div className="px-4 phone-base">
                <div className="side-pane side-pane-second">
                    <AppBar sx={{ position: "relative" }} className="side-pane-header">
                        <div className="d-flex align-center justify-between">
                            <div className="d-flex align-center">
                                <img src={billerInfo?.logo_url} className="head-icon-2 me-2" />
                                <div>
                                    <p className="m-0">{directUserInfo?.map((data) => data["customer_name"])}</p>
                                </div>
                            </div>
                            <div>
                                <img src={BharatBillpay} />
                            </div>
                        </div>
                    </AppBar>

                    {props?.error ? (
                        <p className="text-danger">{props?.error}</p>
                    ) : (
                        directUserInfo?.map((data, index) => {
                            return <PayEMiDetails data={data} amount={amount} key={index} />;
                        })
                    )}
                </div>

                {/* Bill Pay Step Six */}
                <Dialog fullScreen open={BillPaySixopen} onClose={BillPaySixClose} TransitionComponent={Transition} className="side-pane side-pane-second">
                    <AppBar sx={{ position: "relative" }} className="side-pane-header">
                        <div className="d-flex align-center justify-between">
                            <div onClick={BillPaySixClose} className="d-flex align-center">
                                <p className="ms-1 m-0 tx-20">
                                    <strong>PayEMI</strong>
                                </p>
                            </div>
                            <div>
                                <img src={BharatBillpay} />
                            </div>
                        </div>
                    </AppBar>
                    <div className="bg-gray">
                        <div className="py-3 px-3">
                            <div className="row">
                                <div className="col-2 text-start">
                                    <img src={billerInfo?.logo_url} />
                                </div>
                                <div className="col-10">
                                    <p className="m-0">{directUserInfo?.map((data) => data.customer_name)}</p>
                                    <p className="m-0">Car Loan</p>
                                    <p className="m-0">Rs. {inputAmount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-0 px-3">
                        <div className="container-fluid">
                            <div className="mt-4">
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <p className="ms-1 mb-4 tx-20 text-center">
                                            <strong>Paying {billerInfo?.billerName}</strong>
                                        </p>
                                        <div className="w-auto d-flex align-center">
                                            <p className="tx-20 mb-0 me-2 text-muted">
                                                <strong>&#8377;</strong>
                                            </p>

                                            {exactness === "EXACT_DOWN" ? (
                                                <input
                                                    name="Batch"
                                                    type="text"
                                                    className="form-control form-control-blank"
                                                    onChange={(e) => setInputAmount(e.target.value)}
                                                    defaultValue={inputAmount}
                                                ></input>
                                            ) : exactness === "EXACT_UP" ? (
                                                <input
                                                    name="Batch"
                                                    type="text"
                                                    className="form-control form-control-blank"
                                                    onChange={(e) => setInputAmount(e.target.value)}
                                                    defaultValue={inputAmount}
                                                ></input>
                                            ) : exactness === "EXACT" ? (
                                                <input
                                                    name="Batch"
                                                    type="text"
                                                    className="form-control form-control-blank"
                                                    defaultValue={inputAmount}
                                                    disabled
                                                ></input>
                                            ) : (
                                                <input
                                                    name="Batch"
                                                    type="text"
                                                    className="form-control form-control-blank"
                                                    defaultValue={inputAmount}
                                                    disabled
                                                ></input>
                                            )}
                                        </div>
                                        <p className="text-danger">{error}</p>
                                        <p className="text-danger">{amountError}</p>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <input
                                            name="Batch"
                                            placeholder="Enter remark"
                                            type="text"
                                            className="form-control bg-gray"
                                            onChange={(e) => setRemark(e.target.value)}
                                        ></input>
                                        <p className="text-muted lh-1 mt-1">
                                            <small>
                                                <em>Once you have paid this, your loan account will be immediately updated.</em>
                                            </small>
                                        </p>
                                    </div>
                                </div>

                                <div className="row mb-3 mt-4 pt-4">
                                    <div className="col-12">
                                        <button onClick={BillPaySevenClickOpen} type="submit" className="btn btn-primary w-100">
                                            Pay
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
            {props?.loading && <PeSpinner />}
        </>
    );
}

export default SubDomainPayEmi;
