import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";

// Images
import Hdfc from "../Static/Images/hdfc.webp";
import GooglePlay from "../Static/Images/google-play-logo.webp";
import ScratchCard from "../Static/Images/scratch-half-2.png";
import CarRound from "../Static/Images/Car-PO.webp";
import Warning from "../Static/Images/warning.gif";
import Fail from "../Static/Images/order-fail.gif";

import { useGlobalContext } from "../context";
import { useHistory, useParams } from "react-router-dom";
import PeSpinner from "../Components/PeSpinner";
import axios from "axios";
import swal from "sweetalert";

import BelowIcon from "../Components/BelowIcon";
import BillDetails from "../Components/BillDetails";
import LoginButton from "../Components/LoginButton";
import {getBaseUrl} from "../API/getBaseUrl";
var fileDownload = require("js-file-download");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const TransitionUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PayemiProcessing = () => {
  const { BillPaySevenopen, BillPaySevenClose } = useGlobalContext();

  const [error, setError] = useState("");
  const [billerInfo, setBillerInfo] = useState();
  const [loading, setLoading] = useState(true);
  const amount = localStorage.getItem("amount");
  const data = localStorage.getItem("guest-fetch-bill-data");
  const fetchBill = JSON.parse(data);
  const guest_bill_id = fetchBill?.map((data) => data.id);

  const guest_biller_info = JSON.parse(
    localStorage.getItem("guest-biller-info")
  );

  const mobileNumber = localStorage.getItem("guest-mobile-number");
  const subDomain = localStorage.getItem("subDomain");

  let history = useHistory();
  let count = 0;
  let apiCount = 0;
  useEffect(() => {
    setInterval(() => {
      apiCount = apiCount + 1;
      if (apiCount < 6) {
        fetch(
          `${getBaseUrl}getbilldetails-noauth/?bill_id=${guest_bill_id}`,
          {
            method: "GET",
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setLoading(false);
            if (data?.error) {
              setError(data.message);
            } else {
              setLoading(false);
              setBillerInfo(data?.data);
              setError("");

              const status = data?.data[0]?.transation_status;
              if (status == "SU") {
                window.location.href = "/success";
              } else if (status == "FA") {
                window.location.href = "/failure";
              } else {
                history.push("/processing");
                // count = count + 1;
                // console.log(count);
                // if (count === 5) {
                //   swal({
                //     title: "Pending!",
                //     text: "Still Now Your Payement is Pending Now! So please Login now then you can see your payement status",
                //     icon: "warning",
                //     button: "Log in",
                //   }).then(() => {
                //     window.location.href = window.location.href
                //       .replace(subDomain + ".", "")
                //       .replace("processing", `verify-otp/${mobileNumber}`);
                //   });
                // }
              }
            }
          });
      }
    }, 10000);
  }, []);

  const handleDownload = async (id) => {
    fetch(`${getBaseUrl}getPaymentReceiptPDFNoauth/?bill_id=${id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.error) {
          swal("Sorry", `${data?.message}!`, "success");
        }
        fileDownload(data?.file_url, "payment_receipt.pdf");
      });
  };

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={BillPaySevenClose}
      TransitionComponent={TransitionUp}
      className="side-pane side-pane-second"
    >
      <AppBar sx={{ position: "relative" }} className="side-pane-header">
        <div className="d-flex align-center justify-between"></div>
      </AppBar>
      <div className="col-12">
        <p className="text-center">
          <img src={Warning} className="w-50" />
        </p>
        <p className="mb-4 tx-23 text-center text-warning">
          Payment Processing
        </p>

        <p className="mb-0 text-center text-black">
          <span>To </span> {guest_biller_info?.billerName} Bank Limited
        </p>

        <p className="mb-4 text-center text-muted">CAR Loan</p>
        <div className="w-auto d-flex align-center justify-center mb-2">
          <p className="tx-23 mb-0 me-2 text-muted">&#8377;</p>

          <p className="tx-60 mb-0 me-2 text-black weight-300 lh-1">{amount}</p>
        </div>
      </div>
      {billerInfo?.map((data) => (
        <div key={data.id} className="py-0 px-3">
          <div className="container-fluid">
            <div className="mt-0">
              <div className="row mb-3">
                <p className="mb-0 text-center text-black">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    xmlns="http://www.w3.org/2000/svg"
                    className="me-2"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path
                        d="M8.206 15.902a7.703 7.703 0 0 1-7.704-7.7C.502 3.947 3.952.5 8.206.5a7.703 7.703 0 0 1 7.705 7.701c0 4.253-3.45 7.701-7.705 7.701z"
                        fill="#FFA300"
                      />
                      <path
                        d="M7.506 5.401a.7.7 0 0 1 1.4 0v2.8a.7.7 0 0 1-1.4 0v-2.8zM8.206 10.302a.7.7 0 1 1 0 1.4.7.7 0 0 1 0-1.4z"
                        fill="#FFF"
                      />
                    </g>
                  </svg>
                  Processing | {data["transaction_date_and_time"]}
                </p>
              </div>

              <div className="row mb-3">
                <div className="col-12">
                  <div className="tx-16 text-black">Receipt</div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-center justify-between">
                    <p className="m-0 pe-3 text-muted">Biller name</p>
                    <p className="m-0">
                      <strong>{data?.customer_name}</strong>
                    </p>
                  </div>
                </div>
              </div>

              <BillDetails data={data} />
              <div className="row mb-5 mt-4 pt-0">
                <LoginButton name={"processing"} />
                <div className="col-6">
                  <button
                    type="submit"
                    className="btn btn-outline-primary w-100"
                    onClick={(e) => handleDownload(data?.id)}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="#412E8D" fillRule="evenodd">
                        <path d="M4.125 14.438a.687.687 0 1 1 1.375 0V16.5c0 .38.308.688.688.688h9.625c.38 0 .687-.308.687-.688v-2.063a.687.687 0 1 1 1.375 0V16.5c0 1.14-.923 2.063-2.063 2.063H6.188A2.062 2.062 0 0 1 4.124 16.5v-2.063z" />
                        <path d="M13.264 10.514a.687.687 0 1 1 .972.972l-2.75 2.75a.687.687 0 0 1-.972 0l-2.75-2.75a.687.687 0 1 1 .972-.972L11 12.778l2.264-2.264z" />
                        <path d="M10.313 4.125a.687.687 0 1 1 1.374 0v9.625a.687.687 0 1 1-1.374 0V4.125z" />
                      </g>
                    </svg>
                    Download
                  </button>
                </div>
              </div>
              <BelowIcon />
            </div>
          </div>
        </div>
      ))}
      {loading && <PeSpinner />}
    </Dialog>
  );
};

export default PayemiProcessing;
