import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Slide from "@mui/material/Slide";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../context";
import PayEMiDetails from "../Components/PayEMiDetails";
import { FiChevronLeft } from "react-icons/fi";
import BharatBillpay from "../Static/Images/bharat-billpay.png";
import {getBaseUrl} from "../API/getBaseUrl";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});
const TransitionUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function PayEmi(props) {
    const [inputAmount, setInputAmount] = useState();
    const [amountError, setAmountError] = useState("");
    const [error, setError] = useState("");

    // localstorage get items
    const data = JSON.parse(localStorage.getItem("fetch_bill_data"));
    const userPaymentInfo = data?.payload;
    const exactness = data?.billerPaymentExactness;
    const billerPaymentChannel = data?.billerPaymentChannel?.INT;
    const maxAmount = billerPaymentChannel?.maxAmount;
    const minAmount = billerPaymentChannel?.minAmount;
    const { BillPaySevenopen, BillPaySevensetOpen, BillPaySixopen, BillPaySixsetOpen, BillPaySevenClose } = useGlobalContext();
    const history = useHistory();
    {
        /* Bill Pay Step Six */
    }

    const BillPaySixClickOpen = () => {
        BillPaySixsetOpen(true);
    };
    const BillPaySixClose = () => {
        BillPaySixsetOpen(false);
    };

    {
        /* Bill Pay Step Seven */
    }

    const bn = JSON.parse(localStorage.getItem("currentBankBranch"))?.billerName;
    const amount = userPaymentInfo?.map((data) => parseInt(data["amount"]));
    const [emi, setEmi] = useState();
    let totalAmount;

    useEffect(() => {
        const emi1 = userPaymentInfo?.map((data) => setEmi(data.emi));
    }, [emi]);

    if (bn === "TVS Credit") {
        if (emi) {
            totalAmount = emi;
        } else {
            totalAmount = amount;
        }
    } else {
        totalAmount = amount;
    }
    // input amount use effect
    useEffect(() => {
        if (totalAmount) {
            if (exactness == "EXACT") {
                setInputAmount(totalAmount);
            } else if (!exactness) {
                setInputAmount(inputAmount);
            } else if (exactness == "EXACT_DOWN") {
                setInputAmount(totalAmount);
            } else if (exactness == "EXACT_UP") {
                setInputAmount(inputAmount);
            } else {
                setInputAmount(inputAmount);
            }
        }
    }, [exactness]);

    const profie_id = userPaymentInfo?.map((data) => data?.profile_id);
    const bill_id = userPaymentInfo?.map((data) => data?.id);
    const [remark, setRemark] = useState("");
    const BillPaySevenClickOpen = (e) => {
        fetch(
            `${getBaseUrl}CreateOrder/?id=${profie_id}&bill_id=${bill_id}&amount=${inputAmount}&notes=${remark.replace(/ /g, "")}`,

            {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data?.error) {
                    setError(data?.message);
                } else {
                    // razor pay
                    const options = {
                        key: "rzp_live_ZtOSmut8p6fuCX",
                        amount: inputAmount + "00",
                        currency: "INR",
                        name: localStorage.getItem("full_name"),
                        order_id: `${data?.order_id}`,
                        handler: function (response) {
                            localStorage.setItem("amount", inputAmount);
                            BillPaySevensetOpen(true);
                            history.push("/processing");
                        },
                        notes: {
                            address: "Razorpay Corporate Office",
                        },
                        theme: {
                            color: "#3399cc",
                        },
                        retry: {
                            enabled: false,
                        },
                    };
                    let rzp1 = new window.Razorpay(options);
                    rzp1.on("payment.failed", function (response) {
                        localStorage.setItem("amount", inputAmount);
                        history.push("/failure");
                    });
                    if (inputAmount < minAmount / 100) {
                        setAmountError(`your amount should be greater than ${minAmount / 100} `);
                    } else if (maxAmount / 100 < inputAmount) {
                        setAmountError(`your amount should be greater than and less than ${maxAmount / 100}`);
                    } else {
                        if (exactness == "EXACT_DOWN") {
                            if (totalAmount < inputAmount) {
                                setAmountError(`Your Value less than ${totalAmount}`);
                                console.log("done", inputAmount);
                                setError("");
                            } else {
                                rzp1.open();
                                setAmountError("");
                                console.log(maxAmount);
                                setError("");
                            }
                        } else if (exactness == "EXACT_UP") {
                            if (inputAmount < totalAmount) {
                                setAmountError(`Your Value increase than ${totalAmount}`);
                                console.log("done");
                                setError("");
                            } else {
                                rzp1.open();
                                console.log("object");
                                setAmountError("");
                                setError("");
                                console.log(maxAmount);
                            }
                        }
                    }
                }
            });

        e.preventDefault();
    };

    return (
        <>
            <div className="px-4 phone-base">
                <div className="side-pane side-pane-second">
                    <AppBar sx={{ position: "relative" }} className="side-pane-header">
                        <div className="d-flex align-center justify-between">
                            <div className="d-flex align-center">
                                <img alt="bank logo" src={JSON.parse(localStorage.getItem("currentBankBranch"))?.logo_url} className="head-icon-2 me-2" />
                                <div>
                                    <p className="m-0">{userPaymentInfo?.map((data) => data["customer_name"])}</p>
                                    {/* <p className="m-0">
                    {
                      JSON.parse(localStorage.getItem("currentBankBranch"))
                        ?.billerName
                    }
                  </p> */}
                                    {/* <p className="m-0 text-muted">
                    {localStorage.getItem("currentCategory")}
                  </p> */}
                                </div>
                            </div>
                            <div>
                                <img src={BharatBillpay} />
                            </div>
                        </div>
                    </AppBar>

                    {userPaymentInfo?.map((data) => (
                        <PayEMiDetails data={data} amount={emi} key={data?.profile_id} />
                    ))}
                </div>
                {/* Bill Pay Step Six */}
                <Dialog fullScreen open={BillPaySixopen} onClose={BillPaySixClose} TransitionComponent={Transition} className="side-pane side-pane-second">
                    <AppBar sx={{ position: "relative" }} className="side-pane-header">
                        <div className="d-flex align-center justify-between">
                            <div onClick={BillPaySixClose} className="d-flex align-center" style={{ cursor: "pointer" }}>
                                <p className="ms-1 m-0 tx-20">
                                    <strong>
                                        <FiChevronLeft />
                                        PayEMI
                                    </strong>
                                </p>
                            </div>
                            <div>
                                <img src={BharatBillpay} />
                            </div>
                        </div>
                    </AppBar>
                    <div className="bg-gray">
                        <div className="py-3 px-3">
                            <div className="row">
                                <div className="col-2 text-start">
                                    <img src={JSON.parse(localStorage.getItem("currentBankBranch"))?.logo_url} />
                                </div>
                                <div className="col-10">
                                    <p className="m-0">{userPaymentInfo?.map((data) => data["customer_name"])}</p>
                                    <p className="m-0">{localStorage.getItem("currentCategory")}</p>
                                    <p className="m-0">Rs. {inputAmount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="py-0 px-3">
                        <div className="container-fluid">
                            <div className="mt-4">
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <p className="ms-1 mb-4 tx-20 text-center">
                                            <strong>Paying {bn}</strong>
                                        </p>
                                        <div className="w-auto d-flex align-center">
                                            <p className="tx-20 mb-0 me-2 text-muted">
                                                <strong>&#8377;</strong>
                                            </p>

                                            {exactness === "EXACT_DOWN" ? (
                                                <input
                                                    name="Batch"
                                                    type="text"
                                                    className="form-control form-control-blank"
                                                    onChange={(e) => setInputAmount(e.target.value)}
                                                    defaultValue={inputAmount}
                                                />
                                            ) : exactness === "EXACT_UP" ? (
                                                <input
                                                    name="Batch"
                                                    type="text"
                                                    className="form-control form-control-blank"
                                                    onChange={(e) => setInputAmount(e.target.value)}
                                                    defaultValue={inputAmount}
                                                />
                                            ) : exactness === "EXACT" ? (
                                                <input
                                                    name="Batch"
                                                    type="text"
                                                    className="form-control form-control-blank"
                                                    defaultValue={inputAmount}
                                                    disabled
                                                />
                                            ) : (
                                                <input
                                                    name="Batch"
                                                    type="text"
                                                    className="form-control form-control-blank"
                                                    defaultValue={inputAmount}
                                                    disabled
                                                />
                                            )}
                                        </div>
                                        <p className="text-danger">{amountError}</p>
                                        <p className="text-danger">{error}</p>
                                    </div>
                                </div>

                                <div className="row mb-4">
                                    <div className="col-12">
                                        <input
                                            name="Batch"
                                            placeholder="Enter remark"
                                            type="text"
                                            className="form-control bg-gray"
                                            onChange={(e) => setRemark(e.target.value)}
                                        />
                                        <p className="text-muted lh-1 mt-1">
                                            <small>
                                                <em>Once you have paid this, your loan account will be immediately updated.</em>
                                            </small>
                                        </p>
                                    </div>
                                </div>

                                <div className="row mb-3 mt-4 pt-4">
                                    <div className="col-12">
                                        <button onClick={BillPaySevenClickOpen} type="submit" className="btn btn-primary w-100">
                                            Pay
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default PayEmi;
