import React from "react";

const Conditions = () => {
  return (
    <div className="py-3 container">
      <h1 className="pb-3">Terms & Conditions</h1>
      <div>
        <p>
          This document is an electronic record in terms of the Information
          Technology Act, 2000 (as may be amended, modified, re-enacted,
          consolidated or replaced from time to time) and rules thereunder
          pertaining to electronic records in various statutes as applicable and
          amended from time to time, and is published in accordance with the
          provisions of Rule 3 of the Information Technology (Intermediaries
          Guidelines and Digital Media Ethics Code) Rules, 2021 which mandates
          the publishing of rules and regulations, privacy policy and the terms
          and conditions for access or usage of the platforms, namely,
          payemi.net (the “Website”), and mobile application namely “payemi”
          (the “Application”) for Android and iOS devices [hereinafter
          collectively referred to as the “Platform(s)”]. This electronic record
          is generated by a computer system and does not require any physical,
          electronic or digital signature.
        </p>
        <p className="pt-3">
          For the purpose of these terms & conditions for use (“Terms and
          Conditions for Use”/ “Terms”), wherever the context so requires,
          “You”, “Your” and “Customer (s)” shall mean any natural or legal
          person who has agreed to become a service recipient on the Platform by
          visiting or accessing the Platform(s) of Medius Technologies Pvt Ltd,
          a company incorporated under the Companies Act, 2013 having its office
          at Plot No 153, Shere Punjab Colony, Andheri East, Mumbai - 400093,
          and its affiliates (“payemi”/ “We”/ “Us”/ “Our”).
        </p>
        <p className="pt-3">
          By using and/or accessing the Platforms, You accept these Terms and
          enter into a binding contract with payemi. If You do not want to be
          bound by these Terms, You must not access and/or use the Platform in
          any manner whatsoever. ​
        </p>
        <p className="pt-3">
          PayEMI reserves the right to alter, change, modify, add or remove
          portions in whole or in part of these Terms at any time. Such changes
          shall be effective when posted on the Platform. Notwithstanding
          anything to the contrary, Customer shall be responsible for regularly
          reviewing the Terms, including amendments thereto as may be posted on
          the Platform and shall be deemed to have accepted the amended Terms by
          continuing the use of Platform.
        </p>
        <p>
          By downloading Our mobile application from Google Play Store or Apple
          App Store, the Customer will also be subject to Google Play Terms of
          Service or Apple App Store Terms of Service, respectively. If there is
          any conflict between Google Play Store Terms of Service or Apple App
          Store Terms of Service and these Terms & Conditions for Use with
          respect to a Customer’s use of the android and/or iOS mobile platform
          application, then, these Terms & Conditions for Use shall prevail. ​
        </p>
        <p>DEFINITIONS In these Terms, references to:</p>
        <ul>
          <li>
            Customer Account” shall mean the account created by You on the
            Platform in accordance with these Terms.
          </li>
          <li>
            Customer Account Information” shall mean the information provided by
            You for the purpose of creation of the Customer Account.
          </li>
          <li>
            Customer Order” shall mean an order request placed by the Customer
            on the Platform to avail the Services.
          </li>
          <li>
            “Force Majeure Event” means an act of god, war, civil disturbance,
            strike, lockout, act of terrorism, epidemic, pandemic, flood, fire,
            computer crashes, virus attacks, explosion or legislation or
            restriction by any government or other authority, or any change in
            Applicable Law or any other similar circumstance beyond the control
            of payemi Bullion, which has the effect of wholly or partially
            suspending the obligations hereunder
          </li>
          <li>
            “Person” shall mean an individual, a corporation, a partnership, a
            joint venture, a trust, an unincorporated organization and any other
            legal entity.
          </li>
          <li>
            Platform” means the website(s), apps, tools, platforms and/or other
            devices of payemi and its affiliated companies through which the
            Service (defined hereinafter) is (made) available.
          </li>
          <li>Product” shall mean (i) loan EMI</li>
          <li>
            “Services” shall include but not be limited to facilitating paying
            loan EMIs of various lenders under BBPS, customer care services and
            ancillary services thereto provided to the Customers through
            Platform(s)
          </li>
        </ul>
        <p>
          Legal Capacity and Age of Consent: Our Services are restricted to
          Customers who are 18 years of age or older. We do not permit Customers
          under the age of 18 on Our Platform and We do not knowingly collect
          personal information from anyone under the age of 18. Accessibility is
          limited only to those who are not prohibited from entering into a
          contract of service of this kind by laws applicable in their competent
          jurisdiction. With this understanding, You specifically agree that by
          using the Platforms, You are at least 18 years of age and You are
          competent under law to enter into a legally binding and enforceable
          contract.
        </p>
      </div>
      <div>
        <h5>
          REGISTRATION AND SUSPENSION OF CUSTOMER ACCOUNT AND CUSTOMER
          OBLIGATIONS
        </h5>
        <ul>
          <li>
            To enable Your access and use of the Services, You are obligated to
            register on the Platform by providing certain information which
            shall be verified with a one-time password sent to Your mobile
            number and/or e-mail address. Upon successful registration on the
            Platforms, the Customer(s) will be entitled to a virtual space i.e.
            Customer Account which shall be utilized solely to avail the
            Services, and is not to be utilized for any other non-permitted
            purposes. You shall be responsible for the correctness of
            information provided to payemi from time to time. In the event there
            is an error in the information furnished by You or change in the
            existing information, You shall immediately provide correct/updated
            information.
          </li>
          <li>
            payemi shall be entitled to collect and store relevant information
            and documents as provided by the Customer on the Platform for KYC
            purposes. As and when required by payemi, the Customer will be
            required to provide additional documents for fulfilling the KYC
            requirements. You authorize payemi to make such enquiries as may be
            necessary to satisfy about the validity of your identity.
          </li>
          <li>
            payemi may, at its sole discretion, suspend or terminate or block
            access of Customer Account and cease Your access to the Platform,
            with or without notice, in the following scenarios:
          </li>
          <ol>
            <li>
              if there appears to be a fraudulent or suspicious activity in
              his/her account or You are involved in any unlawful activity or
              the Customer Account is used for any unlawful purpose, and may
              intimate the relevant authorities of such unlawful activities; and
            </li>
            <li>
              We have reasonable grounds to suspect that such KYC documents
              and/or Customer Account Information is untrue, inaccurate, not
              current or incomplete, or not in accordance with these Terms and
              Conditions of Use. You hereby undertake to indemnify and keep
              indemnified payemi against any and all losses, claims, liabilities
              costs etc. which arise out of or relating to Your failure to
              identify Yourself and validate Your account promptly and / or due
              to incorrect KYC documents / information
            </li>
          </ol>
          <li>
            You fully acknowledge and understand that You shall be solely liable
            and responsible for maintaining the confidentiality of Your Customer
            Account and passwords, including the device on which such account is
            created, all the activities undertaken under Your Customer Account
            and any consequences therefrom. You agree to immediately notify
            payemi of any unauthorised use of the Customer Account Information
            or any other breach of security. payemi cannot and will not be
            liable for any loss (direct or indirect), damage, mental agony or
            inconvenience arising from Your failure to comply with this section.
          </li>
        </ul>
      </div>
      <div>
        <h5>USE OF THE PLATFORM AND SERVICES</h5>
        <ul>
          <li>
            You acknowledge that the Services are for Your personal use only and
            agree not to sell, publish, trade, resell or exploit for any
            commercial purposes, any portion of the Services or Product
            purchased via the Platform. You shall not modify, copy, distribute,
            transmit, display, perform, reproduce, publish, license, create
            derivative works from, transfer, or sell any information, software,
            products, services or intellectual property rights obtained while
            availing the Services.
          </li>
          <li>To use the Services on the Platform, the Customer agrees to:</li>
          <ul>
            <li>
              not breach or circumvent any laws, third-party rights or Our
              systems, policies, or determinations of Your account status;
            </li>
            <li>
              not use the Platform for committing any criminal offence or any
              unlawful activity for any fraudulent purposes or illegal purposes;
            </li>
            <li>
              not use any robot, spider, scraper, data mining tools, data
              gathering and extraction tools, or other automated means to modify
              or interfere with Our Platform or access the Platform or other
              third-party information for any purpose;
            </li>
            <li>
              not use the Platform for harassing any person or third party, or
              for making any defamatory, obscene, indecent remarks
            </li>
          </ul>
          <li>
            payemi may discontinue some or all of the Services, including
            certain features and the support for certain devices and Platforms,
            at any time.
          </li>
          <li>
            You acknowledge that any data including but not limited to the
            pricing of the Products and typographical errors may occur or maybe
            inaccurately displayed on the Platform. Therefore, in the event that
            Product is listed at an incorrect price or with incorrect
            information due to an error in pricing or product information, then
            We have the right to correct it without any prior notice- this may
            cause changes in price of the Products. Further, We reserve the
            right, at our sole discretion, to refuse or cancel or reject any
            orders placed for the Products.
          </li>
          <li>
            The provision of Services may be interrupted, including for
            maintenance, repairs, upgrades, or network or equipment failures.
            payemi strives to keep the Services up and running; however, all
            online services suffer occasional disruptions and outages beyond the
            control of payemi. Therefore, payemi shall not be responsible for
            errors, negligence, inability to execute orders, delays in
            transmission, delivery or execution of order due to breakdown or
            failure of transmission or communication facilities, or to any other
            cause beyond payemi’s control or anticipation.
          </li>
        </ul>
      </div>
      <div>
        <h5>CUSTOMER CARE AND GRIEVANCE REDRESSAL</h5>
        <p>
          If you have any questions, comments, requests or require any support
          or assistance, You may reach out to our Customer support team at:{" "}
          <br />
          Email: support@payemi.net <br />
          In the event You have any complaints regarding these Terms or the
          services, You may address the same as follows: <br />
          Level: 1 Grievance Officer <br />
          Any complaints, abuse or concerns with regards to the use, processing
          and disclosure of Information provided by you or breach of these terms
          or any applicable law should immediately be informed to the designated
          Grievance Officer mentioned below: <br />
          Name of Grievance Officer: Nitin Purswani <br />
          Designation: Head of Operations <br />
          Email id: support@payemi.net <br />
          Address: Plot No 153, Shere Punjab Colony, Andheri East, Mumbai -
          400093
          <br />
          Level: 2 Nodal Officer <br />
          If your issue remains unresolved to your satisfaction despite
          escalating to our Grievance Officer, you can reach out to our Nodal
          Officer as mentioned below: <br />
          Name of Nodal Officer: Nitin Purswani <br />
          Email id: support@payemi.net
        </p>
        <p className="pt-3">
          Notice of Copyright Infringement: Our policy is to comply with all
          Intellectual Property Laws and to act expeditiously upon receiving any
          notice of claimed infringement. If you believe that any work has been
          reproduced on this website in a manner that constitutes copyright
          infringement, please provide a notice of copyright infringement
          containing all of the following information:
        </p>
        <ol>
          <li>
            A physical or electronic signature of a person authorized to act on
            behalf of the copyright owner for the purposes of the complaint. ​
          </li>
          <li>
            Identification of the copyrighted work claimed to have been
            infringed.
          </li>
          <li>
            Identification of the material on our website that is claimed to be
            infringing or to be the subject of infringing activity.
          </li>
          <li>
            The address, telephone number or e-mail address of the complaining
            party.
          </li>
        </ol>
        <ul>
          <li>
            The Platform may allow You to post Your review, comments,
            suggestions and experience of using the Platform and the Services
            (“Feedback“) in order to improve the Platform and the user
            experience.
          </li>
          <li>
            The Feedbacks shall be deemed to be non-confidential and
            non-compensatory in nature. payemi reserves the right, at its sole
            discretion to use such information in any manner as deemed
            appropriate by payemi and such use shall be entirely unrestricted
          </li>
          <li>
            You further represent and warrant that while posting any Feedback on
            the Platform, You shall not use any offensive, libelous, derogatory,
            hateful or racially or ethnically objectionable language. Further,
            You shall not post any content on any part of the Platform that is
            obscene, pornographic and that all such Feedback will be in
            accordance with applicable law(s).
          </li>
        </ul>
      </div>
      <div>
        <p>
          INTELLECTUAL PROPERTY RIGHTS (IPR) RESTRICTION
          <ol>
            <li>
              payemi solely and exclusively owns respectively any and all the
              trademarks, copyrights, service marks, logos, brands and service
              marks and other intellectual and proprietary rights associated
              with the Services and displayed on/accessed on the Platform. Use
              of the Platform is, and at all times, governed by and subject to
              the copyright, trademark, patent, and trade secret laws regarding
              ownership and use of intellectual property. You shall ensure that
              any intellectual property pertaining to the Services and the
              Platform is not infringed, passed off, diluted,
              reverse-engineered, hacked into, misappropriated, tampered with
              and shall be solely responsible for any violations of any laws and
              for any infringements of any intellectual property rights caused
              by use of the Platform through Your device.
            </li>
            <li>
              The Platform and any underlying technology or software used in
              connection with the Platform may contain rights of payemi or Our
              affiliates or any third-party connected thereto. For use of any
              third-party’s intellectual property, the Customer may need to get
              permission directly from such third-party owner of that
              intellectual property and the owners have a right to take
              appropriate actions against the Customer for any violation,
              infringement or passing off.
            </li>
            <li>
              You undertake to not reproduce, copy, modify or adapt,
              communicate, publish, reveal, supply, distribute or commercially
              exploit any intellectual property under these Terms.
            </li>
            <li>
              The Customer confirms and undertakes not to display or use the
              names, logos, marks, labels, trademarks, copyrights or
              intellectual and proprietary rights of any third party on the
              Platform
            </li>
          </ol>
        </p>
      </div>
      <div>
        <h5>INDEMNIFICATION</h5>
        <p>
          You hereby agree to indemnify and keep payemi and/or its directors,
          affiliates, employees, agents workers or representative indemnified
          from and against all liabilities, actions, claims, demands,
          proceedings, losses, damages, costs, charges and expenses including
          reasonable attorney’s fees, directly or indirectly, whatsoever which
          payemi may at any time incur, sustain, suffer or be put to as a
          consequence of or by reason of or arising out of: (i) the usage of the
          Platform by the Customer; (ii) by reason of payemi acting in good
          faith and taking or refusing to take or omitting to take action on the
          Customer’s instructions, and in particular arising directly or
          indirectly out of the negligence, mistake or misconduct of the
          Customer; (iii) breach or non-compliance of the Terms relating to the
          Customer Account; (iv) fraud or dishonesty relating to any transaction
          by the Customer; and/or (v) in respect of any third party claims that
          may be initiated including for infringement of third party
          intellectual property rights.
        </p>
      </div>
      <div>
        <h5>LIMITATION OF LIABILITY</h5>
        <p>
          Notwithstanding anything otherwise contained in these Terms, in no
          event will payemi and their affiliates, officers, directors,
          employees, agents, suppliers or licensors be liable to any person for
          any indirect, incidental, special, punitive, cover or consequential
          damages (including, without limitation, damages for lost profits, lost
          revenue, lost sales, lost goodwill, loss of use or lost content,
          impact on business, business interruption, loss of anticipated
          savings, loss of business opportunity) however caused, under any
          theory of liability, including, without limitation, contract, tort,
          warranty, breach of statutory duty, negligence or otherwise, even if
          payemi have been advised as to the possibility of such damages or
          could have foreseen such damages. To the maximum extent permitted by
          applicable law, payemi Bullion’s aggregate liability and that of their
          affiliates, officers, employees, agents, suppliers and licensors,
          however arising in connection with the Services, or in connection with
          the performance or contemplated performance of this Agreement exceed
          total amount paid by You, if any, for access to that particular
          Products or Services.
        </p>
      </div>
      <div>
        <h5>DISCLAIMER OF WARRANTIES</h5>
        <ol>
          <li>
            ALL INFORMATION, CONTENT, MATERIALS AND SERVICES INCLUDED ON OR
            OTHERWISE MADE AVAILABLE TO YOU THROUGH THE PLATFORM (COLLECTIVELY,
            THE “CONTENTS”) ARE PROVIDED BY payemi ON AN “AS IS,” “AS AVAILABLE”
            BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND TO THE
            FULLEST EXTENT PERMITTED BY APPLICABLE LAW. payemi EXPRESSLY
            DISCLAIM ANY AND ALL CONDITIONS, REPRESENTATIONS, WARRANTIES OR
            OTHER TERMS, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
            TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
            PARTICULAR PURPOSE, AND NONINFRINGEMENT.
          </li>
          <li>
            payemi SHALL HAVE NO RESPONSIBILITY FOR ANY DAMAGE TO YOUR COMPUTER
            SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY
            CONTENT, MATERIALS, DOCUMENT OR INFORMATION OR ANY OTHER LOSSES
            INCURRED BY YOU FOR USE OF THE PLATFORM. YOU EXPRESSLY AGREE THAT
            THE USE OF THE PLATFORM IS AT YOUR SOLE RISK.
          </li>
          <li>GENERAL OBLIGATIONS</li>
          <li>
            These Terms shall be governed by and interpreted and construed in
            accordance with the laws of India. The courts in New Delhi shall
            have exclusive jurisdiction in respect of any matters arising
            therefrom.
          </li>
          <li>
            You shall be responsible for payment of all fees/costs/charges
            associated with availing of Services from Us and You agree to bear
            any and all applicable taxes including but not limited to GST,
            duties, cess etc
          </li>
          <li>
            The parties are independent contractors. These Terms do not create a
            partnership, franchise, joint venture, agency, fiduciary or
            employment relationship among the parties
          </li>
          <li>
            If any part of these Terms is determined to be indefinite, invalid,
            or otherwise unenforceable, the rest of these Terms shall continue
            in full force.
          </li>
          <li>
            Disclaimer of warranties, Confidentiality, Limitation of liability,
            Governing law provisions, Indemnity. Intellectual Property Rights
            shall survive any termination of these Terms.
          </li>
          <li>
            Any failure or delay by a party to enforce or exercise any provision
            of these Terms of Use, or any related right, shall not constitute a
            waiver by such party of that provision or right. To clarify, if a
            User breaches any of these conditions and We do not initiate any
            action against the same, We will still be entitled to use our rights
            and remedies in any other situation where You breach these
            conditions. Any waiver by a party shall only be made in writing and
            executed by a duly authorized officer of such party.
          </li>
          <li>
            payemi shall not be liable for any failure or delay in performance
            of any obligation, under this Agreement, to the extent such failure
            or delay is due to a Force Majeure Event. Such non-performance by
            payemi shall, in no manner whosoever, amount to a breach of its
            obligations herein.
          </li>
        </ol>
        <ul>
          <li>Customer Consent</li>
          <ul>
            <li>
              You consent to receive SMS messages (including text messages),
              WhatsApp messages (including text messages), calls and messages
              (including pre-recorded and artificial voice and auto-dialed) from
              us, our agents, representatives, affiliates or anyone calling on
              our behalf at the specific number(s) you have provided to us, with
              information or questions about your application, loan and/or
              account. You represent that you are permitted to receive calls at
              each of the telephone numbers you have provided to us. You agree
              to promptly alert us whenever you stop using a particular
              telephone number. Your cellular or mobile telephone provider will
              charge you according to the type of plan you carry.
            </li>
          </ul>
        </ul>
      </div>
    </div>
  );
};

export default Conditions;
